export default function getLanguageInfo(
  currentLanguage: string,
  infoCode: string,
): string {
  if (infoCode === 'nav_home') {
    if (currentLanguage === 'portuguese') {
      return 'Home';
    }
    if (currentLanguage === 'english') {
      return 'Home';
    }
    if (currentLanguage === 'spanish') {
      return 'Inicio';
    }
  }

  if (infoCode === 'nav_about_us') {
    if (currentLanguage === 'portuguese') {
      return 'Quem Somos';
    }
    if (currentLanguage === 'english') {
      return 'About Us';
    }
    if (currentLanguage === 'spanish') {
      return 'Quiénes Somos';
    }
  }

  if (infoCode === 'nav_our_story') {
    if (currentLanguage === 'portuguese') {
      return 'Nossa História';
    }
    if (currentLanguage === 'english') {
      return 'Our Story';
    }
    if (currentLanguage === 'spanish') {
      return 'Nuestra Historia';
    }
  }

  if (infoCode === 'nav_production') {
    if (currentLanguage === 'portuguese') {
      return 'Produção';
    }
    if (currentLanguage === 'english') {
      return 'Production';
    }
    if (currentLanguage === 'spanish') {
      return 'Producción';
    }
  }

  if (infoCode === 'nav_certifications') {
    if (currentLanguage === 'portuguese') {
      return 'Certificações';
    }
    if (currentLanguage === 'english') {
      return 'Certifications';
    }
    if (currentLanguage === 'spanish') {
      return 'Certificaciones';
    }
  }

  if (infoCode === 'nav_people_management') {
    if (currentLanguage === 'portuguese') {
      return 'Gestão de Pessoas';
    }
    if (currentLanguage === 'english') {
      return 'People Management';
    }
    if (currentLanguage === 'spanish') {
      return 'Gestión de Personas';
    }
  }

  if (infoCode === 'nav_contact') {
    if (currentLanguage === 'portuguese') {
      return 'Contato';
    }
    if (currentLanguage === 'english') {
      return 'Contact';
    }
    if (currentLanguage === 'spanish') {
      return 'Contacto';
    }
  }

  if (infoCode === 'nav_privacy_policy') {
    if (currentLanguage === 'portuguese') {
      return 'Política de Privacidade';
    }
    if (currentLanguage === 'english') {
      return 'Privacy Policy';
    }
    if (currentLanguage === 'spanish') {
      return 'Política de Privacidad';
    }
  }

  if (infoCode === 'nav_cookies_policy') {
    if (currentLanguage === 'portuguese') {
      return 'Política de Cookies';
    }
    if (currentLanguage === 'english') {
      return 'Cookies Policy';
    }
    if (currentLanguage === 'spanish') {
      return 'Política de Cookies';
    }
  }

  if (infoCode === 'nav_restricted_access') {
    if (currentLanguage === 'portuguese') {
      return 'Acesso Restrito';
    }
    if (currentLanguage === 'english') {
      return 'Restricted Access';
    }
    if (currentLanguage === 'spanish') {
      return 'Acceso Restringido';
    }
  }

  if (infoCode === 'aboutus_title') {
    if (currentLanguage === 'portuguese') {
      return 'QUEM SOMOS';
    }
    if (currentLanguage === 'english') {
      return 'ABOUT US';
    }
    if (currentLanguage === 'spanish') {
      return 'QUIENES SOMOS';
    }
  }

  if (infoCode === 'aboutus_subtitle_01') {
    if (currentLanguage === 'portuguese') {
      return 'A ';
    }
    if (currentLanguage === 'english') {
      return '';
    }
    if (currentLanguage === 'spanish') {
      return '';
    }
  }

  if (infoCode === 'aboutus_subtitle_02') {
    if (currentLanguage === 'portuguese') {
      return 'AVÍCOLA ';
    }
    if (currentLanguage === 'english') {
      return 'AVÍCOLA ';
    }
    if (currentLanguage === 'spanish') {
      return 'AVÍCOLA ';
    }
  }

  if (infoCode === 'aboutus_subtitle_03') {
    if (currentLanguage === 'portuguese') {
      return 'PATO BRANCO…';
    }
    if (currentLanguage === 'english') {
      return 'PATO BRANCO…';
    }
    if (currentLanguage === 'spanish') {
      return 'PATO BRANCO…';
    }
  }

  if (infoCode === 'aboutus_mission_01') {
    if (currentLanguage === 'portuguese') {
      return 'Oferecer produtos de qualidade';
    }
    if (currentLanguage === 'english') {
      return 'To offer quality products';
    }
    if (currentLanguage === 'spanish') {
      return 'Ofrecer productos de calidad';
    }
  }

  if (infoCode === 'aboutus_mission_02') {
    if (currentLanguage === 'portuguese') {
      return 'ao cliente, respeitando as relações';
    }
    if (currentLanguage === 'english') {
      return 'to our costumers, respecting the';
    }
    if (currentLanguage === 'spanish') {
      return 'al cliente, respetando las relaciones';
    }
  }

  if (infoCode === 'aboutus_mission_03') {
    if (currentLanguage === 'portuguese') {
      return 'humanas e o meio ambiente,';
    }
    if (currentLanguage === 'english') {
      return 'enviroment and human relations,';
    }
    if (currentLanguage === 'spanish') {
      return 'humanas y el medio ambiente,';
    }
  }

  if (infoCode === 'aboutus_mission_04') {
    if (currentLanguage === 'portuguese') {
      return 'gerando valor e excelência';
    }
    if (currentLanguage === 'english') {
      return 'producing value and excelence';
    }
    if (currentLanguage === 'spanish') {
      return 'generando valor y excelencia';
    }
  }

  if (infoCode === 'aboutus_mission_05') {
    if (currentLanguage === 'portuguese') {
      return 'nos resultados.';
    }
    if (currentLanguage === 'english') {
      return 'on our results.';
    }
    if (currentLanguage === 'spanish') {
      return 'en resultados.';
    }
  }

  if (infoCode === 'aboutus_vision_01') {
    if (currentLanguage === 'portuguese') {
      return 'Ser referência na';
    }
    if (currentLanguage === 'english') {
      return 'Being reference in';
    }
    if (currentLanguage === 'spanish') {
      return 'Ser una referencia en la';
    }
  }

  if (infoCode === 'aboutus_vision_02') {
    if (currentLanguage === 'portuguese') {
      return 'qualidade de seus';
    }
    if (currentLanguage === 'english') {
      return 'quality on our';
    }
    if (currentLanguage === 'spanish') {
      return 'calidad de sus';
    }
  }

  if (infoCode === 'aboutus_vision_03') {
    if (currentLanguage === 'portuguese') {
      return 'produtos e processos.';
    }
    if (currentLanguage === 'english') {
      return 'products and processes.';
    }
    if (currentLanguage === 'spanish') {
      return 'productos y procesos.';
    }
  }

  if (infoCode === 'aboutus_values_01') {
    if (currentLanguage === 'portuguese') {
      return '• Comprometimento;';
    }
    if (currentLanguage === 'english') {
      return '• Compromise;';
    }
    if (currentLanguage === 'spanish') {
      return '• Compromiso;';
    }
  }

  if (infoCode === 'aboutus_values_02') {
    if (currentLanguage === 'portuguese') {
      return '• Transparência e Ética;';
    }
    if (currentLanguage === 'english') {
      return '• Transparency and Ethics;';
    }
    if (currentLanguage === 'spanish') {
      return '• Transparencia y Ética;';
    }
  }

  if (infoCode === 'aboutus_values_03') {
    if (currentLanguage === 'portuguese') {
      return '• Responsabilidade Social e Ambiental.';
    }
    if (currentLanguage === 'english') {
      return '• Social and Environmental Responsibility.';
    }
    if (currentLanguage === 'spanish') {
      return '• Responsabilidad Social y Ambiental.';
    }
  }

  if (infoCode === 'aboutus_extra_info_01') {
    if (currentLanguage === 'portuguese') {
      return 'A qualidade de nossos produtos é resultado';
    }
    if (currentLanguage === 'english') {
      return 'The quality of our products is the result';
    }
    if (currentLanguage === 'spanish') {
      return '¡La calidad de nuestros productos es el resultado de';
    }
  }

  if (infoCode === 'aboutus_extra_info_02') {
    if (currentLanguage === 'portuguese') {
      return 'da ';
    }
    if (currentLanguage === 'english') {
      return 'of ';
    }
    if (currentLanguage === 'spanish') {
      return 'la ';
    }
  }

  if (infoCode === 'aboutus_extra_info_03') {
    if (currentLanguage === 'portuguese') {
      return 'dedicação';
    }
    if (currentLanguage === 'english') {
      return 'dedication';
    }
    if (currentLanguage === 'spanish') {
      return 'dedicación';
    }
  }

  if (infoCode === 'aboutus_extra_info_04') {
    if (currentLanguage === 'portuguese') {
      return ' e ';
    }
    if (currentLanguage === 'english') {
      return ' and ';
    }
    if (currentLanguage === 'spanish') {
      return ' y el ';
    }
  }

  if (infoCode === 'aboutus_extra_info_05') {
    if (currentLanguage === 'portuguese') {
      return 'amor';
    }
    if (currentLanguage === 'english') {
      return 'love';
    }
    if (currentLanguage === 'spanish') {
      return 'amor';
    }
  }

  if (infoCode === 'aboutus_extra_info_06') {
    if (currentLanguage === 'portuguese') {
      return ' pelo que fazemos!';
    }
    if (currentLanguage === 'english') {
      return ' for what we do!';
    }
    if (currentLanguage === 'spanish') {
      return ' por lo que hacemos!';
    }
  }

  if (infoCode === 'ourstory_title') {
    if (currentLanguage === 'portuguese') {
      return 'NOSSA HISTÓRIA';
    }
    if (currentLanguage === 'english') {
      return 'OUR STORY';
    }
    if (currentLanguage === 'spanish') {
      return 'NUESTRA HISTORIA';
    }
  }

  if (infoCode === 'ourstory_first_section_info_title') {
    if (currentLanguage === 'portuguese') {
      return 'A visão dos fundadores potencializa o presente e o futuro';
    }
    if (currentLanguage === 'english') {
      return 'The vision of the founders enhance the present and the future';
    }
    if (currentLanguage === 'spanish') {
      return 'La visión de los fundadores realza el presente y el futuro';
    }
  }

  if (infoCode === 'ourstory_first_section_first_info_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'O empreendedor nato enxerga, além do seu tempo, oportunidades que ' +
        'podem mudar o futuro e criar histórias empresariais desafiadoras, ' +
        'mas de significativo sucesso. Se deparando com a “Crise do Petróleo” ' +
        'e vivenciando o chamado “Milagre Econômico Brasileiro”, Oliden (Em memória) ' +
        'e Odete Rotava, perceberam o declínio do ciclo da madeira e, numa percepção ' +
        'de vanguarda, optaram por investir na avicultura, no ano de 1970. Ainda na ' +
        'mesma década diversificaram as fontes de receita, passando a atuar, também, ' +
        'na pecuária de bovinos de corte.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'The true entrepreneur sees, beyond his time, opportunities that ' +
        'can change the future and create challenging corporate histories, but ' +
        'of significant success. “Facing the petroleum crisis” and experimenting ' +
        'the called “Brazilian Economic Miracle”, Oliden (In memory of) and ' +
        'Odete Rotava, perceived the decline of the wood cycle and in a vanguard ' +
        'attitude, chose to invest in poultry in the year of 1970. Still on the ' +
        'same decade, diversified the sources of income, beginning to work with ' +
        'beef cattle ranching also.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'El emprendedor nato ve, más allá de su tiempo, oportunidades que ' +
        'pueden cambiar el futuro y crear historias comerciales desafiantes, pero ' +
        'con un éxito significativo. Frente a la "Crisis del Petróleo" y viviendo el ' +
        'llamado "Milagro Económico Brasileño", Oliden (En la memoria) y Odete Rotava, ' +
        'notaron el declive del ciclo de la madera y, en una percepción de vanguardia, ' +
        'optaron por invertir en la avicultura en el año de 1970. En la misma década ' +
        'diversificaron sus fuentes de ingresos, comenzando a actuar, también, en la ' +
        'ganadería de ganado de carne.'
      );
    }
  }

  if (infoCode === 'ourstory_first_section_second_info_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Com garra, coragem e uma imensa vontade de vencer Oliden e Odete Rotava, ' +
        'com o apoio da equipe de profissionais, enfrentaram grandes desafios e ' +
        'venceram: a Avícola Pato Branco, pela excelência no sistema de trabalho, ' +
        'conquistou clientes de expressão nacional do Paraná, de Santa Catarina e ' +
        'São Paulo que contam com a certeza de estar adquirindo produtos desenvolvidos ' +
        'com muita dedicação: pintos de um dia e ovos férteis.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'With strength, courage and a huge will to win, Oliden and Odete Rotava, ' +
        'with the support of the professional team, faced big challenges, and won. ' +
        'Avícola Pato Branco, for the excellence in its work system, conquered clients ' +
        'of national range, from Paraná, Santa Catarina and São Paulo, that count with ' +
        'the certainty of acquiring products developed with a lot of determination: ' +
        'chicks of one day and fertile eggs.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Con vigor, coraje y un inmenso deseo de vencer Oliden y Odete Rotava, ' +
        'con el apoyo del equipo de profesionales, enfrentaron grandes desafíos y ' +
        'ganaron: la Avícola Pato Branco, por su excelencia en el sistema de trabajo, ' +
        'conquistó clientes de renombre nacional en Paraná, Santa Catarina y São Paulo ' +
        'que están seguros de adquirir productos desarrollados con gran dedicación: ' +
        'pollitos de un día y huevos fértiles.'
      );
    }
  }

  if (infoCode === 'ourstory_second_section_info_title') {
    if (currentLanguage === 'portuguese') {
      return 'De geração em geração';
    }
    if (currentLanguage === 'english') {
      return 'From generation to generation';
    }
    if (currentLanguage === 'spanish') {
      return 'De generacion a generacion';
    }
  }

  if (infoCode === 'ourstory_second_section_first_info_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'O tempo passou e a história da Avícola Pato Branco se fundiu com a história ' +
        'da Família Rotava, inclusive na migração de gerações. Na década de 90 a segunda' +
        'geração da família passou a gerir a empresa, não abrindo mão dos valores e do' +
        'desejo contínuo de evolução repassado pelos antecessores, algo que tende a se' +
        'perpetuar com o ingresso da terceira geração.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Time passed and the history of Avícola Pato Branco merged with the history ' +
        'of the Rotava Family, including the migration of generations. In the 90s, the ' +
        'second generation of the family started to manage the company, not giving up the ' +
        'values ​​and continuous desire for evolution passed on by its predecessors, something ' +
        'that tends to be perpetuated with the entry of the third generation.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Pasó el tiempo y la historia de Avícola Pato Branco se fusionó con la historia ' +
        'de la Familia Rotava, incluída la migración de generaciones. En la década de los 90, ' +
        'la segunda generación de la familia pasa a dirigir la empresa, sin renunciar a los ' +
        'valores y el deseo contínuo de evolución transmitido por sus antecesores, algo que ' +
        'tiende a perpetuarse con la entrada de la tercera generación.'
      );
    }
  }

  if (infoCode === 'ourstory_second_section_second_info_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Os netos de Oliden e Odete que passam a conhecer as linhas de trabalho ' +
        'e a seriedade de relação da Empresa com seus colaboradores, clientes, ' +
        'fornecedores, meio ambiente e com a sociedade como um todo.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `The grandchildren of Oliden and Odete who get to know the lines of work ` +
        `and the seriousness of the Company's relationship with its employees, customers, ` +
        `suppliers, the environment and society as a whole.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Los nietos de Oliden y Odete conocen las líneas de trabajo y la seriedad ' +
        'de la relación de la Compañía con sus empleados, clientes, proveedores, el ' +
        'medio ambiente y la sociedad en su conjunto.'
      );
    }
  }

  if (infoCode === 'ourstory_second_section_second_info_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'A Avícola Pato Branco prima pela qualidade de seus produtos e processos e busca, ' +
        'continuamente, ser referência no que faz em suas unidades de produção localizadas ' +
        'em Pato Branco, Mariópolis e Vitorino.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Avícola Pato Branco strives for the quality of its products and processes ' +
        'and continually seeks to be a reference in what it does in its production units  ' +
        'located in Pato Branco, Mariópolis and Vitorino.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'La Avícola Pato Branco se esfuerza en la calidad de sus productos y processos; ' +
        'y busca contínuamente ser un referente en lo que hace, en sus unidades productivas ' +
        'ubicadas en Pato Branco, Mariópolis y Vitorino.'
      );
    }
  }

  if (infoCode === 'ourstory_third_section_info_title') {
    if (currentLanguage === 'portuguese') {
      return 'O segredo da evolução?';
    }
    if (currentLanguage === 'english') {
      return 'The secret of evolution?';
    }
    if (currentLanguage === 'spanish') {
      return '¿El secreto de la evolución?';
    }
  }

  if (infoCode === 'ourstory_third_section_first_info_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Muito trabalho e resiliência. A Avícola Pato Branco gerencia cotidianamente ' +
        'indicadores de qualidade que medem detalhes da eficiência técnica, desde os ' +
        'insumos recebidos (externos) até o andamento do desenvolvimento interno de ' +
        'seus produtos. Há um rigoroso cuidado desde as granjas, onde os manejos com ' +
        'as matrizes são continuamente aprimorados para o máximo de performance possível. ' +
        'O resultado de todo o esforço: clientes vinculados a Avícola Pato Branco, numa ' +
        'relação duradoura de fidelidade e desenvolvimento mútuo.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Hard work and resilience. Avícola Pato Branco daily manages quality ' +
        'indicators that measure details of technical efficiency, from the inputs ' +
        'received (external) to the progress of the internal development of its ' +
        'products. There is a rigorous care from the farms, where the management ' +
        'with the matrices are continuously improved for the maximum possible ' +
        'performance. The result of all the effort: customers linked to ' +
        'Avícola Pato Branco, in a lasting relationship of loyalty and mutual development.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Trabajo duro y resiliencia. La Avícola Pato Branco maneja diariamente ' +
        'indicadores de calidad que miden detalles de la eficiencia técnica, desde los ' +
        'insumos recibidos (externos) hasta el avance del desarrollo interno de sus ' +
        'productos. Existe un riguroso cuidado desde las fincas, donde se mejora ' +
        'continuamente el manejo con las matrices para el máximo rendimiento posible. ' +
        'El resultado de todo el esfuerzo: clientes vinculados a Avícola Pato Branco, ' +
        'en una relación duradera de lealtad y desarrollo mutuo.'
      );
    }
  }

  if (infoCode === 'ourstory_third_section_second_info_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'As conquistas não surgem ao acaso. Carregam consigo dedicação, visão, valores e ' +
        'liderança que passam de geração para geração. O futuro da Avícola Pato Branco ' +
        'certamente reserva transformações, mas apesar das novas tecnologias adotadas e ' +
        'dos novos profissionais envolvidos, algo não muda: o desejo e a prioridade em ' +
        'produzir bem e com o máximo de qualidade possível, tornando os clientes satisfeitos ' +
        'e prósperos em seus, respectivos, amanhãs.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `Achievements do not come by chance. They carry with them dedication, ` +
        `vision, values and leadership that pass from generation to generation. The ` +
        `future of Avícola Pato Branco certainly holds changes, but despite the new ` +
        `technologies adopted and the new professionals involved, something hasn't ` +
        `changed: the desire and priority to produce well and with the highest possible ` +
        `quality, making customers happy and prosperous in their respective, tomorrows.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Los logros no vienen por casualidad. Llevan consigo dedicación, visión, ' +
        'valores y liderazgo que pasan de generación en generación. El futuro de Avícola ' +
        'Pato Branco ciertamente tiene cambios, pero a pesar de las nuevas tecnologías ' +
        'adoptadas y los nuevos profesionales involucrados, algo no ha cambiado: el deseo ' +
        'y la prioridad de producir bien y con la mayor calidad posible, haciendo felices ' +
        'y prósperos a los clientes en sus respectivos mañanas.'
      );
    }
  }

  if (infoCode === 'ourstory_footer') {
    if (currentLanguage === 'portuguese') {
      return 'Avícola Pato Branco, realizando nossos sonhos juntos!';
    }
    if (currentLanguage === 'english') {
      return 'Avícola Pato Branco, making our dreams come true together!';
    }
    if (currentLanguage === 'spanish') {
      return 'Avícola Pato Branco, ¡haciendo realidad nuestros sueños juntos!';
    }
  }

  if (infoCode === 'production_title') {
    if (currentLanguage === 'portuguese') {
      return 'PRODUÇÃO';
    }
    if (currentLanguage === 'english') {
      return 'PRODUCTION';
    }
    if (currentLanguage === 'spanish') {
      return 'PRODUCCIÓN';
    }
  }

  if (infoCode === 'production_chicks_01') {
    if (currentLanguage === 'portuguese') {
      return 'Produto final de todo um';
    }
    if (currentLanguage === 'english') {
      return 'The end product of a';
    }
    if (currentLanguage === 'spanish') {
      return 'Producto final de un';
    }
  }

  if (infoCode === 'production_chicks_02') {
    if (currentLanguage === 'portuguese') {
      return 'trabalho criterioso com';
    }
    if (currentLanguage === 'english') {
      return 'thorough work with';
    }
    if (currentLanguage === 'spanish') {
      return 'minucioso trabajo de';
    }
  }

  if (infoCode === 'production_chicks_03') {
    if (currentLanguage === 'portuguese') {
      return 'manejo, nutrição e';
    }
    if (currentLanguage === 'english') {
      return 'handling, nutrition and';
    }
    if (currentLanguage === 'spanish') {
      return 'manipulación, nutrición y';
    }
  }

  if (infoCode === 'production_chicks_04') {
    if (currentLanguage === 'portuguese') {
      return 'sanidade.';
    }
    if (currentLanguage === 'english') {
      return 'health.';
    }
    if (currentLanguage === 'spanish') {
      return 'salud.';
    }
  }

  if (infoCode === 'production_eggs_01') {
    if (currentLanguage === 'portuguese') {
      return 'A qualidade exigida';
    }
    if (currentLanguage === 'english') {
      return 'The required quality';
    }
    if (currentLanguage === 'spanish') {
      return 'La calidad requerida';
    }
  }

  if (infoCode === 'production_eggs_02') {
    if (currentLanguage === 'portuguese') {
      return 'garante um produto';
    }
    if (currentLanguage === 'english') {
      return 'guarantees a product';
    }
    if (currentLanguage === 'spanish') {
      return 'garantiza un producto';
    }
  }

  if (infoCode === 'production_eggs_03') {
    if (currentLanguage === 'portuguese') {
      return 'dentro dos padrões';
    }
    if (currentLanguage === 'english') {
      return 'within technical and';
    }
    if (currentLanguage === 'spanish') {
      return 'dentro de los estándares';
    }
  }

  if (infoCode === 'production_eggs_04') {
    if (currentLanguage === 'portuguese') {
      return 'técnicos e sanitários.';
    }
    if (currentLanguage === 'english') {
      return 'sanitary standards.';
    }
    if (currentLanguage === 'spanish') {
      return 'técnico y sanitario.';
    }
  }

  if (infoCode === 'certifications_title') {
    if (currentLanguage === 'portuguese') {
      return 'CERTIFICAÇÕES';
    }
    if (currentLanguage === 'english') {
      return 'CERTIFICATIONS';
    }
    if (currentLanguage === 'spanish') {
      return 'CERTIFICACIONES';
    }
  }

  if (infoCode === 'certifications_global_gap_what_is_it_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'A maioria dos varejistas dos mercados de hoje exige ' +
        'determinados referenciais que assegurem uma agricultura ' +
        'segura e sustentável. A certificação GLOBALG.A.P. é bastante ' +
        'conceituada por demonstrar segurança alimentar e sustentabilidade ' +
        'na unidade de produção.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Most retailers in today’s markets require certain ' +
        'benchmarks that ensure safe and sustainable agriculture. ' +
        'The GLOBALG.A.P. certification is highly regarded for ' +
        'demonstrating food safety and sustainability in the production unit.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'La mayoría de los minoristas de los mercados actuales ' +
        'requieren ciertas referencias que garantiza una agricultura ' +
        'segura y sostenible. La certificación GLOBALG.A.P. es bastante ' +
        'respetable por demostrar seguridad alimentaria y sostenibilidad ' +
        'en la unidad de producción.'
      );
    }
  }

  if (infoCode === 'certifications_global_gap_what_is_it_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Através desta certificação, os consumidores e varejistas ' +
        'tem a garantia de que os alimentos atingem níveis aceitos ' +
        'de segurança e qualidade, e que foram produzidos de forma ' +
        'sustentável, respeitando a saúde, segurança e bem-estar dos ' +
        'trabalhadores, o meio ambiente e levando em consideração as ' +
        'questões de bem-estar animal.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Through this certification, consumers and retailers are assured ' +
        'that food reaches accepted levels of safety and quality, and that ' +
        'it has been produced sustainably, respecting the health, safety and ' +
        'well-being of workers, the environment and taking into account animal ' +
        'welfare issues.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'A través de esta certificación, los consumidores y minoristas ' +
        'tienen la seguridad de que los alimentos alcanzan niveles ' +
        'aceptados de seguridad y calidad, y que fueron producidos ' +
        'de forma sostenible, respetando la salud, seguridad y bienestar ' +
        'de los trabajadores, el medio ambiente y teniendo en cuenta las ' +
        'cuestiones de bienestar animales.'
      );
    }
  }

  if (infoCode === 'certifications_global_gap_goals_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Difundir a agricultura segura e sustentável a nível mundial, ' +
        'permitindo atender aos requisitos dos distribuidores em termos ' +
        'de qualidade, segurança de alimentos e práticas ambientais.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Disseminate safe and sustainable agriculture worldwide, ' +
        'allowing to meet the requirements of distributors in terms ' +
        'of quality, food safety and environmental practices.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Difundir la agricultura segura y sostenible en todo el mundo, ' +
        'permitiendo cumplir con los requisitos de los distribuidores en ' +
        'términos de calidad, seguridad de alimentos y prácticas ambientales.'
      );
    }
  }

  if (infoCode === 'certifications_global_gap_goals_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Busca também responder às crescentes exigências relativas à ' +
        'segurança de alimentos e segurança ambiental, e definir normas ' +
        'de produção para o desenvolvimento constante das melhores técnicas ' +
        'de produção agrícola.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'It also seeks to respond to the growing demands regarding ' +
        'food safety and environmental safety, and define production ' +
        'standards for the constant development of the best agricultural ' +
        'production techniques.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'También busca responder a las crecientes demandas relacionadas ' +
        'con la seguridad de los alimentos y la seguridad ambiental, y ' +
        'definir normas de producción para el desarrollo constante de las ' +
        'mejores técnicas de producción agrícola.'
      );
    }
  }

  if (infoCode === 'certifications_global_gap_advantages_topic_01') {
    if (currentLanguage === 'portuguese') {
      return (
        '• Agregar valor nos produtos, ao estar em conformidade com ' +
        'referenciais GLOBALG.A.P. reconhecidos internacionalmente.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        '• Add value to the products by complying with internationally ' +
        'recognized GLOBALG.A.P. references.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        '• Agregar valor en los produtos, al ser en conformidad con ' +
        'referencias GLOBALG.A.P. reconocidos internacionalmente.'
      );
    }
  }

  if (infoCode === 'certifications_global_gap_advantages_topic_02') {
    if (currentLanguage === 'portuguese') {
      return (
        '• Ganhar acesso a novos clientes, mercados, fornecedores e ' +
        'varejistas locais e globais.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        '• Gain access to new customers, markets, suppliers ' +
        'and local and global retailers.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        '• Obtener acceso a nuevos clientes, mercados, ' +
        'proveedores y minoristas locales y globales.'
      );
    }
  }

  if (infoCode === 'certifications_global_gap_advantages_topic_03') {
    if (currentLanguage === 'portuguese') {
      return (
        '• Reduzir exposição a riscos de reputação devido a ' +
        'problemas de segurança alimentar e segurança de produtos.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        '• Reduce exposure to reputational risks due to food ' +
        'safety and product safety issues.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        '• Reducir exposición al riesgo de reputación debido a ' +
        'problemas de seguridad alimentaria y seguridad de los productos.'
      );
    }
  }

  if (infoCode === 'certifications_global_gap_advantages_topic_04') {
    if (currentLanguage === 'portuguese') {
      return (
        '• Aumentar a eficiência da gestão e dos ' +
        'processos nas unidades de produção.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        '• Increase the efficiency of management and ' +
        'processes in the production units.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        '• Incrementar la eficiencia de la gestión y ' +
        'de los procesos en las unidades de producción.'
      );
    }
  }

  if (infoCode === 'certifications_global_gap_advantages_topic_05') {
    if (currentLanguage === 'portuguese') {
      return (
        '• Receber um número GLOBALG.A.P. (GGN) para facilitar ' +
        'a identificação e rastreabilidade a nível B2B.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        '• Receive a GLOBALG.A.P. (GGN) number to facilitate ' +
        'B2B identification and traceability.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        '• Recibir un número GLOBALG.A.P. (GGN) para facilitar ' +
        'la identificación y trazabilidad a nivel B2B.'
      );
    }
  }

  if (infoCode === 'certifications_alo_free_what_is_it_text_01_part_01') {
    if (currentLanguage === 'portuguese') {
      return 'A Lei Suíça SR916.51 ALO ';
    }
    if (currentLanguage === 'english') {
      return 'The Swiss Law SR916.51 ALO ';
    }
    if (currentLanguage === 'spanish') {
      return 'La Ley Suiza SR916.51 ALO ';
    }
  }

  if (infoCode === 'certifications_alo_free_what_is_it_text_01_part_02') {
    if (currentLanguage === 'portuguese') {
      return '(Agricultural Labelling Ordinance) Regulamento de Rotulagem para produtos Agrícolas ';
    }
    if (currentLanguage === 'english') {
      return '(Agricultural Labeling Ordinance) ';
    }
    if (currentLanguage === 'spanish') {
      return '(Agricultural Labelling Ordinance) Reglamento de Etiquetado de Productos Agrícolas ';
    }
  }

  if (infoCode === 'certifications_alo_free_what_is_it_text_01_part_03') {
    if (currentLanguage === 'portuguese') {
      return (
        'obriga que os produtos comercializados em seu país possuam respeito ' +
        'ao meio ambiente e estejam livres de substâncias proibidas – hormônios ' +
        'e antibióticos – para produção agropecuária.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'requires that products sold in your country respect the environment ' +
        'and are free of prohibited substances – hormones and antibiotics – for ' +
        'agricultural production.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'requiere que los productos vendidos en su país respeten el medio ambiente ' +
        'y estén libres de sustancias prohibidas - hormonas y antibióticos - para la ' +
        'producción agrícola.'
      );
    }
  }

  if (infoCode === 'certifications_alo_free_what_is_it_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Para vender no mercado suíço, as empresas devem cumprir as leis do ' +
        'país. Isso inclui testes de conformidade, rastreabilidade do processo ' +
        'produtivo e declarações de conformidade. Os produtos exportados devem ' +
        'atender aos padrões de bem-estar animal. As regras de origem são complexas, ' +
        'exigindo produção no país de origem ou transformação substancial lá.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'To sell on the Swiss market, companies must comply with the laws of the ' +
        'country. This includes compliance testing, production process traceability ' +
        'and compliance declarations. Exported products must meet animal welfare ' +
        'standards. The rules of origin are complex, requiring production in the ' +
        'country of origin or substantial processing there.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Para vender en el mercado suizo, las empresas deben cumplir con las leyes de ' +
        'su país. Esto incluye pruebas de cumplimiento, trazabilidad del proceso de ' +
        'producción y declaraciones de cumplimiento. Los productos exportados deben ' +
        'cumplir con los estándares de bienestar animal. Las reglas de origen son complejas ' +
        'y requieren producción en el país de origen o un procesamiento sustancial allí.'
      );
    }
  }

  if (infoCode === 'certifications_alo_free_goals_topic_01') {
    if (currentLanguage === 'portuguese') {
      return '• Exportação para a Suíça.';
    }
    if (currentLanguage === 'english') {
      return '• Export to Switzerland.';
    }
    if (currentLanguage === 'spanish') {
      return '• Exportación a Suiza.';
    }
  }

  if (infoCode === 'certifications_alo_free_goals_topic_02') {
    if (currentLanguage === 'portuguese') {
      return '• Ênfase na qualidade e acabamento do produto.';
    }
    if (currentLanguage === 'english') {
      return '• Emphasis on product quality and finish.';
    }
    if (currentLanguage === 'spanish') {
      return '• Énfasis en la calidad y el acabado del producto.';
    }
  }

  if (infoCode === 'certifications_alo_free_goals_topic_03') {
    if (currentLanguage === 'portuguese') {
      return '• Atenção aos aspectos de proteção ambiental e condições de trabalho na produção.';
    }
    if (currentLanguage === 'english') {
      return '• Attention to aspects of environmental protection and working conditions in production.';
    }
    if (currentLanguage === 'spanish') {
      return (
        '• Atención a los aspectos de protección del medio ambiente y de ' +
        'las condiciones de trabajo en la producción.'
      );
    }
  }

  if (infoCode === 'certifications_alo_free_goals_topic_04') {
    if (currentLanguage === 'portuguese') {
      return '• Conformidade com a lei suíça.';
    }
    if (currentLanguage === 'english') {
      return '• Compliance with Swiss law.';
    }
    if (currentLanguage === 'spanish') {
      return '• Cumplimiento de la ley suiza.';
    }
  }

  if (infoCode === 'certifications_alo_free_goals_topic_05') {
    if (currentLanguage === 'portuguese') {
      return '• Impacto na segurança alimentar e competitividade da cadeia de produção animal.';
    }
    if (currentLanguage === 'english') {
      return '• Impact on food security and competitiveness of the animal production chain.';
    }
    if (currentLanguage === 'spanish') {
      return '• Impacto en la seguridad alimentaria y competitividad de la cadena productiva animal.';
    }
  }

  if (infoCode === 'certifications_alo_free_goals_topic_06') {
    if (currentLanguage === 'portuguese') {
      return '• Representação da indústria em órgãos internacionais relevantes.';
    }
    if (currentLanguage === 'english') {
      return '• Industry representation in relevant international bodies.';
    }
    if (currentLanguage === 'spanish') {
      return '• Representación de la industria en organismos internacionales relevantes.';
    }
  }

  if (infoCode === 'certifications_alo_free_goals_topic_07') {
    if (currentLanguage === 'portuguese') {
      return '• Facilitação da harmonização global das leis de alimentação animal.';
    }
    if (currentLanguage === 'english') {
      return '• Facilitation of global harmonization of animal feed laws.';
    }
    if (currentLanguage === 'spanish') {
      return '• Facilitación de la armonización global de las leyes de alimentación animal.';
    }
  }

  if (infoCode === 'certifications_alo_free_goals_topic_08') {
    if (currentLanguage === 'portuguese') {
      return '• Estímulo à melhoria contínua dos padrões de segurança alimentar.';
    }
    if (currentLanguage === 'english') {
      return '• Encouraging the continuous improvement of food safety standards.';
    }
    if (currentLanguage === 'spanish') {
      return '• Fomentar la mejora continua de los estándares de seguridad alimentaria.';
    }
  }

  if (infoCode === 'peoplemanagement_gallery_title') {
    if (currentLanguage === 'portuguese') {
      return 'GALERIA';
    }
    if (currentLanguage === 'english') {
      return 'GALLERY';
    }
    if (currentLanguage === 'spanish') {
      return 'GALERÍA';
    }
  }

  if (infoCode === 'peoplemanagement_gallery_first_item_title') {
    if (currentLanguage === 'portuguese') {
      return 'Granja de Recria';
    }
    if (currentLanguage === 'english') {
      return 'Breeding Farm';
    }
    if (currentLanguage === 'spanish') {
      return 'Granja de Cría';
    }
  }

  if (infoCode === 'peoplemanagement_gallery_second_item_title') {
    if (currentLanguage === 'portuguese') {
      return 'Granja de Recria';
    }
    if (currentLanguage === 'english') {
      return 'Breeding Farm';
    }
    if (currentLanguage === 'spanish') {
      return 'Granja de Cría';
    }
  }

  if (infoCode === 'peoplemanagement_gallery_third_item_title') {
    if (currentLanguage === 'portuguese') {
      return 'Granja de Recria';
    }
    if (currentLanguage === 'english') {
      return 'Breeding Farm';
    }
    if (currentLanguage === 'spanish') {
      return 'Granja de Cría';
    }
  }

  if (infoCode === 'peoplemanagement_gallery_fourth_item_title') {
    if (currentLanguage === 'portuguese') {
      return 'Incubatório';
    }
    if (currentLanguage === 'english') {
      return 'Hatchery';
    }
    if (currentLanguage === 'spanish') {
      return 'Criadero';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects') {
    if (currentLanguage === 'portuguese') {
      return 'PROJETOS INTERNOS';
    }
    if (currentLanguage === 'english') {
      return 'INTERNAL PROJECTS';
    }
    if (currentLanguage === 'spanish') {
      return 'PROYECTOS INTERNOS';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_party_01_title') {
    if (currentLanguage === 'portuguese') {
      return 'Festa Junina';
    }
    if (currentLanguage === 'english') {
      return 'June Celebration';
    }
    if (currentLanguage === 'spanish') {
      return 'Fiesta Junina';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_party_01_modal_title') {
    if (currentLanguage === 'portuguese') {
      return 'Festa Junina';
    }
    if (currentLanguage === 'english') {
      return 'June Celebration';
    }
    if (currentLanguage === 'spanish') {
      return 'Fiesta Junina';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_party_01_modal_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Algumas imagens de nossa Festa Junina 2022, ocorrida em nossa ' +
        'sede administrativa no dia 15 de junho.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Some images from our Feast of Saint John 2022, which took ' +
        'place at our administrative headquarters on June 15th.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Algunas imágenes de nuestra Fiesta Junina 2022, que se ' +
        'llevó a cabo en nuestra sede administrativa el pasado 15 de junio.'
      );
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_work_at_height_title') {
    if (currentLanguage === 'portuguese') {
      return 'Trabalho em Altura';
    }
    if (currentLanguage === 'english') {
      return 'Work at Height';
    }
    if (currentLanguage === 'spanish') {
      return 'Trabajo em Altura';
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_work_at_height_modal_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return 'Trabalho em Altura';
    }
    if (currentLanguage === 'english') {
      return 'Work at Height';
    }
    if (currentLanguage === 'spanish') {
      return 'Trabajo em Altura';
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_work_at_height_modal_text_01'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'O setor de Segurança no Trabalho da Avícola Pato Branco, ' +
        'juntamente com o SENAR (Serviço Nacional de Aprendizagem Rural) ' +
        'proporcionou recentemente um treinamento de NR (Norma Regulamentadora) ' +
        '35 - Trabalho em Altura, para os colaboradores que desenvolvem atividades ' +
        'de manutenção.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'The Occupational Safety sector of Avícola Pato Branco, together ' +
        'with SENAR (National Rural Learning Service) recently provided ' +
        'training on NR (Regulatory Norm) 35 - Working at Heights, for ' +
        'employees who carry out maintenance activities.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'El sector de Seguridad en el Trabajo de "Avícola Pato Branco", ' +
        'junto con el SENAR (Servicio Nacional de Aprendizaje Rural) brindó ' +
        'recientemente capacitación sobre la NR (Norma Reglamentaria) 35 - ' +
        'Trabajo en Alturas, para los empleados que realizan actividades de mantenimiento.'
      );
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_work_at_height_modal_text_02'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'O intuito do treinamento foi de qualificar e preparar os colaboradores ' +
        'para situações que exijam mão de obra especializada, abrangendo desde o ' +
        'planejamento, organização até a execução das atividades, a fim de garantir ' +
        'a segurança do colaborador e de terceiros, evitando a ocorrência de ' +
        'acidentes. Parabenizamos a todos pelo desempenho e dedicação.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'The purpose of the training was to qualify and prepare employees ' +
        'for situations that require specialized labor, ranging from ' +
        'planning, organization to the execution of activities, in ' +
        'order to guarantee the safety of the employee and third ' +
        'parties, avoiding the occurrence of accidents. We ' +
        'congratulate everyone on their performance and dedication.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'El objetivo de la capacitación fue calificar y preparar a los ' +
        'empleados para situaciones que requieren mano de obra especializada, ' +
        'desde la planificación, organización hasta la ejecución de actividades, ' +
        'con el fin de garantizar la seguridad del empleado y de terceros, evitando ' +
        'la ocurrencia de accidentes. Felicitamos a todos por su desempeño y dedicación.'
      );
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_first_technical_meeting_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return '1º Encontro Técnico';
    }
    if (currentLanguage === 'english') {
      return '1st Technical Meeting';
    }
    if (currentLanguage === 'spanish') {
      return '1ra Reunión Técnica';
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_first_technical_meeting_modal_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return '1º Encontro Técnico';
    }
    if (currentLanguage === 'english') {
      return '1st Technical Meeting';
    }
    if (currentLanguage === 'spanish') {
      return '1ra Reunión Técnica';
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_first_technical_meeting_modal_text'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Encontro ocorrido nos dias 12 e 13 de maio para os nossos ' +
        'monitores. O evento contou com palestrantes das seguintes ' +
        'empresas parceiras: AES Saúde Animal, Cobb América do Sul, ' +
        'Elanco Brasil, MSD Saúde Animal e Royal Pas Reform.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Meeting held on May 12 and 13, 2022 for our monitors. The ' +
        'event featured speakers from the following partner ' +
        'companies: AES Saúde Animal, Cobb América do Sul, ' +
        'Elanco Brasil, MSD Saúde Animal and Royal Pas Reform.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Reunión celebrada los días 12 y 13 de mayo de 2022 ' +
        'para nuestros monitores. El evento contó con disertantes ' +
        'de las siguientes empresas asociadas: AES Saúde Animal, ' +
        'Cobb América do Sul, Elanco Brasil, MSD Saúde Animal y Royal Pas Reform.'
      );
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_leader_development_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return 'Desenvolvimento de Líderes';
    }
    if (currentLanguage === 'english') {
      return 'Leader Development';
    }
    if (currentLanguage === 'spanish') {
      return 'Desarrollo de Líderes';
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_leader_development_modal_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return 'Desenvolvimento de Líderes';
    }
    if (currentLanguage === 'english') {
      return 'Leader Development';
    }
    if (currentLanguage === 'spanish') {
      return 'Desarrollo de Líderes';
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_leader_development_modal_text_01'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Recentemente iniciamos com a empresa Realize Gestão de Pessoas, ' +
        'treinamento e mentoria para os líderes daqui da Avícola, com um ' +
        'olhar principalmente voltado para as pessoas. Este é um momento ' +
        'de novas perspectivas e engajar os colaboradores com a cultura ' +
        'da empresa não é uma tarefa simples.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `We recently started with the company Realize Gestão de Pessoas, ` +
        `training and mentoring the leaders here at Avícola, with a focus ` +
        `primarily on people. This is a time for new perspectives and engaging ` +
        `employees with the company's culture is not an easy task.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Recientemente comenzamos con la empresa Realize Gestão de Pessoas, ' +
        'capacitando y asesorando a los líderes aquí en Avícola, con un enfoque ' +
        'principalmente en las personas. Este es un momento para nuevas perspectivas ' +
        'e involucrar a los empleados con la cultura de la empresa no es una tarea fácil.'
      );
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_leader_development_modal_text_02'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'A mentoria vem para desenvolver os nossos líderes, para ' +
        'que esses atendam as expectativas da empresa, superem ' +
        'desafios e estejam aptos a lidar com situações inesperadas ' +
        'do dia a dia. Para tanto, necessitam que seus conhecimentos ' +
        'técnicos e comportamentais estejam em constante processo de evolução.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `The mentoring comes to develop our leaders, so that they ` +
        `meet the company's expectations, overcome challenges and are ` +
        `able to deal with unexpected day-to-day situations. Therefore, ` +
        `they need their technical and behavioral knowledge to be in a ` +
        `constant process of evolution.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'El mentoring viene a desarrollar a nuestros líderes, para ' +
        'que cumplan con las expectativas de la empresa, superen desafíos ' +
        'y sean capaces de enfrentar situaciones inesperadas del día a día. Para ' +
        'ello, necesitan que sus conocimientos técnicos y conductuales estén en ' +
        'constante proceso de evolución.'
      );
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_fire_brigade_title') {
    if (currentLanguage === 'portuguese') {
      return 'Brigada de Incêndio';
    }
    if (currentLanguage === 'english') {
      return 'Fire Brigade';
    }
    if (currentLanguage === 'spanish') {
      return 'Cuerpo de Bomberos';
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_fire_brigade_modal_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return 'Brigada de Incêndio';
    }
    if (currentLanguage === 'english') {
      return 'Fire Brigade';
    }
    if (currentLanguage === 'spanish') {
      return 'Cuerpo de Bomberos';
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_fire_brigade_modal_text_01'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Tendo em vista que a brigada de incêndio tem como intuito ' +
        'preservar a vida e os bens de uma instituição, é ela que ' +
        'age diante de princípios de incêndio e na prestação de ' +
        'socorro em situações incomuns. No mês de março de 2022, ' +
        'foi realizado treinamento com novos brigadistas, ' +
        'juntamente com o SENAR (Serviço Nacional de Aprendizagem Rural).'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `Considering that the fire brigade aims to preserve the life ` +
        `and assets of an institution, it is the one that acts in the ` +
        `face of fire principles and in providing assistance in unusual ` +
        `situations. In March 2022, training was carried out with new ` +
        `brigade members, together with SENAR (National Rural Learning Service).`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Considerando que el cuerpo de bomberos tiene como objetivo ' +
        'preservar la vida y el patrimonio de una institución, es el ' +
        'que actúa frente a los principios del fuego y en brindar ' +
        'socorro en situaciones inusuales. En marzo de 2022 se realizó ' +
        'la capacitación a nuevos brigadistas, en conjunto con el SENAR ' +
        '(Servicio Nacional de Aprendizaje Rural).'
      );
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_fire_brigade_modal_text_02'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Nele, nossos brigadistas aprenderam tanto a parte teórica quanto ' +
        'a parte prática do atendimento de primeiros socorros e do combate ' +
        'a incêndio, sendo realizadas atividades de controle e extinção de ' +
        'focos de incêndio utilizando os extintores e hidrantes, também sendo ' +
        'feitas simulações de atendimentos em caso de acidentes. Agradecemos a ' +
        'todos pelo esforço e dedicação ao treinamento e parabenizamos os novos brigadistas.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `In it, our firefighters learned both the theoretical and the ` +
        `practical part of first aid care and fire fighting, being carried ` +
        `out activities to control and extinguish fires using fire extinguishers ` +
        `and hydrants, also being made simulations of assistance in case of ` +
        `accidents. We thank everyone for the effort and dedication to training ` +
        `and congratulate the new brigadistas.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'En él, nuestros bomberos aprendieron tanto la parte teórica ' +
        'como la práctica de primeros auxilios y extinción de incendios, ' +
        'realizándose actividades de control y extinción de incendios ' +
        'mediante extintores e hidrantes, realizándose también simulacros ' +
        'de asistencia en caso de siniestros. Agradecemos a todos el esfuerzo ' +
        'y dedicación en la formación y felicitamos a los nuevos brigadistas.'
      );
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_childrens_day_title') {
    if (currentLanguage === 'portuguese') {
      return 'Dia das Crianças';
    }
    if (currentLanguage === 'english') {
      return `Children's Day`;
    }
    if (currentLanguage === 'spanish') {
      return 'Día de los Niños';
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_childrens_day_modal_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return 'Dia das Crianças';
    }
    if (currentLanguage === 'english') {
      return `Children's Day`;
    }
    if (currentLanguage === 'spanish') {
      return 'Día de los Niños';
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_childrens_day_modal_text_01'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'No mês de outubro de 2021, em comemoração ao Dia das Crianças, ' +
        'foi realizada a entrega de brindes para os filhos dos nossos ' +
        'colaboradores menores de 12 anos.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `In October 2021, in celebration of Children's Day, gifts were ` +
        `delivered to the children of our employees under the age of 12.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'En octubre de 2021, en celebración del Día de los Niños, ' +
        'se entregaron obsequios a los hijos de nuestros colaboradores menores de 12 años.'
      );
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_childrens_day_modal_text_02'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Foi muito gratificante ver a felicidade de cada criança e o ' +
        'carinho dos pais conosco: "Obrigado por lembrar dos nossos pequenos ' +
        'nesta data. São esses pequenos detalhes que fazem, cada dia mais, ter ' +
        'vontade de vestir esta camisa", ressalta um de nossos colaboradores. Isso ' +
        'faz valer a pena todo esforço e empenho nosso.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `It was very gratifying to see the happiness of each child and the ` +
        `affection of the parents with us: "Thank you for remembering our ` +
        `little ones on this date. It's these little details that make me ` +
        `want to wear this shirt more and more every day", emphasizes one ` +
        `of our employees. This makes all our effort and effort worthwhile.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Fue muy gratificante ver la felicidad de cada niño y el cariño ' +
        'de los papás con nosotros: “Gracias por recordar a nuestros pequeños ' +
        'en esta fecha. Son estos pequeños detalles los que me dan más y más ganas ' +
        'de llevar esta camiseta cada día”, enfatiza uno de nuestros empleados. Esto ' +
        'hace que todo nuestro esfuerzo y empeño valga la pena.'
      );
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_50_years_title') {
    if (currentLanguage === 'portuguese') {
      return '50 Anos';
    }
    if (currentLanguage === 'english') {
      return '50 Years';
    }
    if (currentLanguage === 'spanish') {
      return '50 Años';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_50_years_modal_title') {
    if (currentLanguage === 'portuguese') {
      return '50 Anos da Avícola Pato Branco';
    }
    if (currentLanguage === 'english') {
      return '50 Years of Avícola Pato Branco';
    }
    if (currentLanguage === 'spanish') {
      return '50 Años de la Avícola Pato Branco';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_50_years_modal_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Em nosso aniversário de 50 anos, nós da Avícola Pato Branco ' +
        'convidamos você a conhecer um pouco mais da nossa história.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'On our 50th anniversary, we at Avícola Pato Branco invite you to learn ' +
        'a little more about our history.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'En nuestro aniversario de 50 años, nosotros de la Avícola Pato Branco te ' +
        'invitamos a conocer un poco más de nuestra historia.'
      );
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_employees_title') {
    if (currentLanguage === 'portuguese') {
      return 'Colaboradores';
    }
    if (currentLanguage === 'english') {
      return 'Employees';
    }
    if (currentLanguage === 'spanish') {
      return 'Empleados';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_employees_modal_title') {
    if (currentLanguage === 'portuguese') {
      return 'Gratidão por Fazer Parte!';
    }
    if (currentLanguage === 'english') {
      return 'Gratitude for Being a Part!';
    }
    if (currentLanguage === 'spanish') {
      return '¡Gratitud por Ser Parte!';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_employees_modal_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'O maior patrimônio que a Avícola Pato Branco possui são seus ' +
        'colaboradores. É comprometimento, dedicação, honestidade e ' +
        'seriedade. Nas imagens, apresentamos os colaboradores recebendo ' +
        'uma homenagem, lembrando o período que eles estão vestindo a ' +
        'camisa da Avícola, trabalhando em equipe e remando na mesma direção.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'The greatest asset that Avícola Pato Branco has are its employees. It ' +
        'is commitment, dedication, honesty and seriousness. In the images, we ' +
        'present employees receiving a tribute, remembering the period when they ' +
        'are wearing the company shirt, working as a team and paddling in the same ' +
        'direction.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'El mayor activo que la Avícola Pato Branco tiene son sus empleados. Es ' +
        'compromiso, dedicación, honestidad y seriedad. En las imágenes, presentamos ' +
        'los empleados recibiendo un homenaje, recordando la época en que visten la ' +
        'camiseta de la Avícola Pato Branco, trabajando en equipo y remando en la ' +
        'misma dirección.'
      );
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_pink_october_title') {
    if (currentLanguage === 'portuguese') {
      return 'Outubro Rosa';
    }
    if (currentLanguage === 'english') {
      return 'Pink October';
    }
    if (currentLanguage === 'spanish') {
      return 'Octubre Rosa';
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_pink_october_modal_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return 'Outubro Rosa';
    }
    if (currentLanguage === 'english') {
      return 'Pink October';
    }
    if (currentLanguage === 'spanish') {
      return 'Octubre Rosa';
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_pink_october_modal_text'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Hoje contamos com a participação da Vânia, um exemplo de ' +
        'resiliência, onde ela fala sobre sua história de superação ' +
        'com o câncer e suas lutas diárias. O quanto importante é o ' +
        'tratamento, a realização de exames preventivos e apoio do ' +
        'Grupo de Apoio a Mama (Gama), onde dá um suporte completo ao ' +
        'paciente e familiares que estão enfrentando a luta contra o ' +
        'câncer e outras doenças.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Today we have the participation of Vânia, an example of resilience, ' +
        'where she talks about her story of overcoming cancer and her daily ' +
        'struggles. How important is the treatment, preventive examinations ' +
        'and support from the Breast Support Group (Gama), which provides ' +
        'complete support to patients and their families who are facing the ' +
        'fight against cancer and other diseases.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Hoy contamos con la participación de Vânia, un ejemplo de resiliencia, ' +
        'donde cuenta su historia de superación con el cáncer y sus luchas diarias. ' +
        'Cuán importante es el tratamiento, la realización de exámenes preventivos y ' +
        'el apoyo del Grupo de Apoyo de Mama (Gama), donde da un apoyo completo al ' +
        'paciente y a la família que enfrentan la lucha contra el cáncer y otras enfermedades.'
      );
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_christmas_title') {
    if (currentLanguage === 'portuguese') {
      return 'Natal';
    }
    if (currentLanguage === 'english') {
      return 'Christmas';
    }
    if (currentLanguage === 'spanish') {
      return 'Navidad';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_christmas_modal_title') {
    if (currentLanguage === 'portuguese') {
      return 'Entrega Cestas de Natal';
    }
    if (currentLanguage === 'english') {
      return 'Delivery Christmas Baskets';
    }
    if (currentLanguage === 'spanish') {
      return 'Entrega Cestas de Navidad';
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_christmas_modal_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'O clima do Natal tomou conta dos ares da Avícola! Mesmo em ' +
        'um ano com tantos acontecimentos atípicos que atingiram o ' +
        'mundo, não poderia ser deixado de presentear quem está sempre ' +
        'ao nosso lado, cumprindo suas tarefas com dedicação, empenho e ' +
        'multiplicando sorrisos com a gente. Por isso, a Avícola entregou ' +
        'um presente de Natal, como um singelo agradecimento pela colaboração ' +
        'durante o ano de 2020.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'The Christmas atmosphere took over the environment of Avícola Pato Branco! Even ' +
        'in a year with so many unusual events that hit the world, we could not ' +
        'fail to give gifts to those who are always by our side, fulfilling their ' +
        'tasks with dedication, commitment and multiplying smiles with us. Therefore, ' +
        'Avícola Pato Branco delivered a Christmas gift, as a simple thank you for the ' +
        'collaboration during the year 2020.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        '¡El clima de navideño se apoderó del aires de la Avícola Pato Branco! Mismo en un ' +
        'año con tantos acontecimientos atípicos que golpeó el mundo, no podíamos ' +
        'dejar de dar regalos a quienes siempre están a nuestro lado, cumpliendo sus ' +
        'tareas con dedicación, compromiso y multiplicando sonrisas con la gente. Por ' +
        'eso, la Avícola entregó un regalo de Navidad, como un simple agradecimiento por ' +
        'la colaboración durante el año 2020.'
      );
    }
  }

  if (infoCode === 'peoplemanagement_internal_projects_blue_november_title') {
    if (currentLanguage === 'portuguese') {
      return 'Novembro Azul';
    }
    if (currentLanguage === 'english') {
      return `Blue November`;
    }
    if (currentLanguage === 'spanish') {
      return 'Noviembre Azul';
    }
  }

  if (
    infoCode === 'peoplemanagement_internal_projects_blue_november_modal_title'
  ) {
    if (currentLanguage === 'portuguese') {
      return 'Novembro Azul';
    }
    if (currentLanguage === 'english') {
      return `Blue November`;
    }
    if (currentLanguage === 'spanish') {
      return 'Noviembre Azul';
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_blue_november_modal_text_01'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Em alusão ao Novembro Azul, a Avícola Pato Branco juntamente ' +
        'com o Dr. Gabriel, a enfermeira Analine e agente de saúde ' +
        'Verediana, da UBS Morumbi, promoveu um momento de informação ' +
        'sobre o câncer de próstata, ressaltando a importância do ' +
        'autocuidado, a realização dos exames periódicos, além de ' +
        'apresentar dados e esclarecer dúvidas dos participantes ' +
        'sobre o câncer que mais atinge os homens.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `In allusion to Blue November, Avícola Pato Branco together ` +
        `with Dr. Gabriel, nurse Analine and health agent Verediana, ` +
        `from UBS Morumbi, promoted a moment of information about ` +
        `prostate cancer, emphasizing the importance of self-care, ` +
        `carrying out periodic exams, in addition to presenting data ` +
        `and clarifying doubts of the participants about cancer that ` +
        `affects men the most.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'En alusión a Noviembre Azul, Avícola Pato Branco junto al ' +
        'Dr. Gabriel, la enfermera Analine y la agente de salud ' +
        'Verediana, de la UBS Morumbi, promovieron un momento de ' +
        'información sobre el cáncer de próstata, enfatizando la ' +
        'importancia del autocuidado, realizándose exámenes ' +
        'periódicos, además de presentar datos y aclarar dudas ' +
        'de los participantes sobre el cáncer que afecta los hombres más.'
      );
    }
  }

  if (
    infoCode ===
    'peoplemanagement_internal_projects_blue_november_modal_text_02'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Agradecemos a UBS Morumbi e a todos que participaram ' +
        'deste momento. Faça parte dessa luta, previna-se!'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `We thank UBS Morumbi and everyone who participated in ` +
        `this moment. Be part of this fight, prevent yourself!`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Agradecemos a UBS Morumbi ya todos los que participaron ' +
        'de este momento. ¡Sé parte de esta lucha, prevente!'
      );
    }
  }

  if (infoCode === 'contact_title') {
    if (currentLanguage === 'portuguese') {
      return 'CONTATO';
    }
    if (currentLanguage === 'english') {
      return 'CONTACT';
    }
    if (currentLanguage === 'spanish') {
      return 'CONTACTO';
    }
  }

  if (infoCode === 'contact_subtitle_01') {
    if (currentLanguage === 'portuguese') {
      return 'Fale conosco, será um prazer ';
    }
    if (currentLanguage === 'english') {
      return 'Talk to us, it will be a pleasure to ';
    }
    if (currentLanguage === 'spanish') {
      return '¡Habla con nosotros, será un placer ';
    }
  }

  if (infoCode === 'contact_subtitle_02') {
    if (currentLanguage === 'portuguese') {
      return 'atendê-lo!';
    }
    if (currentLanguage === 'english') {
      return 'serve you!';
    }
    if (currentLanguage === 'spanish') {
      return 'atenderte!';
    }
  }

  if (infoCode === 'contact_logo_info_01') {
    if (currentLanguage === 'portuguese') {
      return 'Trabalhe conosco!';
    }
    if (currentLanguage === 'english') {
      return 'Work with us!';
    }
    if (currentLanguage === 'spanish') {
      return '¡Trabaja con nosotros!';
    }
  }

  if (infoCode === 'contact_logo_info_02') {
    if (currentLanguage === 'portuguese') {
      return 'Envie seu currículo por este formulário.';
    }
    if (currentLanguage === 'english') {
      return 'Submit your resume using this form.';
    }
    if (currentLanguage === 'spanish') {
      return 'Envíe su currículum mediante este formulario.';
    }
  }

  if (infoCode === 'contact_form_name') {
    if (currentLanguage === 'portuguese') {
      return 'Nome';
    }
    if (currentLanguage === 'english') {
      return 'Name';
    }
    if (currentLanguage === 'spanish') {
      return 'Nombre';
    }
  }

  if (infoCode === 'contact_form_telephone') {
    if (currentLanguage === 'portuguese') {
      return 'Telefone';
    }
    if (currentLanguage === 'english') {
      return 'Telephone';
    }
    if (currentLanguage === 'spanish') {
      return 'Teléfono';
    }
  }

  if (infoCode === 'contact_form_email') {
    if (currentLanguage === 'portuguese') {
      return 'E-mail';
    }
    if (currentLanguage === 'english') {
      return 'E-mail';
    }
    if (currentLanguage === 'spanish') {
      return 'Correo Electrónico';
    }
  }

  if (infoCode === 'contact_form_to_email_op_default') {
    if (currentLanguage === 'portuguese') {
      return 'Selecione o Setor';
    }
    if (currentLanguage === 'english') {
      return 'Choose the Department';
    }
    if (currentLanguage === 'spanish') {
      return 'Seleccione el Sector';
    }
  }

  if (infoCode === 'contact_form_to_email_op_01') {
    if (currentLanguage === 'portuguese') {
      return 'Comercial';
    }
    if (currentLanguage === 'english') {
      return 'Commercial';
    }
    if (currentLanguage === 'spanish') {
      return 'Comercial';
    }
  }

  if (infoCode === 'contact_form_to_email_op_02') {
    if (currentLanguage === 'portuguese') {
      return 'Compras';
    }
    if (currentLanguage === 'english') {
      return 'Purchasing';
    }
    if (currentLanguage === 'spanish') {
      return 'Compras';
    }
  }

  if (infoCode === 'contact_form_to_email_op_03') {
    if (currentLanguage === 'portuguese') {
      return 'Direitos dos Titulares (LGPD)';
    }
    if (currentLanguage === 'english') {
      return 'Rights of Owners (LGPD)';
    }
    if (currentLanguage === 'spanish') {
      return 'Derechos de los Titulares (LGPD)';
    }
  }

  if (infoCode === 'contact_form_to_email_op_04') {
    if (currentLanguage === 'portuguese') {
      return 'Financeiro';
    }
    if (currentLanguage === 'english') {
      return 'Financial';
    }
    if (currentLanguage === 'spanish') {
      return 'Financiero';
    }
  }

  if (infoCode === 'contact_form_to_email_op_05') {
    if (currentLanguage === 'portuguese') {
      return 'Recursos Humanos';
    }
    if (currentLanguage === 'english') {
      return 'Human Resources';
    }
    if (currentLanguage === 'spanish') {
      return 'Recursos Humanos';
    }
  }

  if (infoCode === 'contact_form_message') {
    if (currentLanguage === 'portuguese') {
      return 'Digite sua mensagem';
    }
    if (currentLanguage === 'english') {
      return 'Enter your message';
    }
    if (currentLanguage === 'spanish') {
      return 'Ingrese su mensaje';
    }
  }

  if (infoCode === 'contact_form_file_button') {
    if (currentLanguage === 'portuguese') {
      return 'Escolher arquivo';
    }
    if (currentLanguage === 'english') {
      return 'Choose file';
    }
    if (currentLanguage === 'spanish') {
      return 'Elija el archivo';
    }
  }

  if (infoCode === 'contact_form_file_info') {
    if (currentLanguage === 'portuguese') {
      return 'Nenhum arquivo selecionado';
    }
    if (currentLanguage === 'english') {
      return 'No file selected';
    }
    if (currentLanguage === 'spanish') {
      return 'Ningún archivo seleccionado';
    }
  }

  if (infoCode === 'contact_form_field_error_message') {
    if (currentLanguage === 'portuguese') {
      return 'Campo obrigatório';
    }
    if (currentLanguage === 'english') {
      return 'Required field';
    }
    if (currentLanguage === 'spanish') {
      return 'Campo obligatorio';
    }
  }

  if (infoCode === 'contact_form_success_title') {
    if (currentLanguage === 'portuguese') {
      return 'Sucesso!';
    }
    if (currentLanguage === 'english') {
      return 'Success!';
    }
    if (currentLanguage === 'spanish') {
      return 'Éxito!';
    }
  }

  if (infoCode === 'contact_form_success_description') {
    if (currentLanguage === 'portuguese') {
      return 'Mensagem enviada com sucesso';
    }
    if (currentLanguage === 'english') {
      return 'Message sent successfully';
    }
    if (currentLanguage === 'spanish') {
      return 'Mensaje enviado correctamente';
    }
  }

  if (infoCode === 'contact_form_error_title') {
    if (currentLanguage === 'portuguese') {
      return 'Ocorreu um erro!';
    }
    if (currentLanguage === 'english') {
      return 'An error has occurred!';
    }
    if (currentLanguage === 'spanish') {
      return 'Ocurrio un error!';
    }
  }

  if (infoCode === 'contact_form_error_description') {
    if (currentLanguage === 'portuguese') {
      return 'Não foi possível enviar a mensagem';
    }
    if (currentLanguage === 'english') {
      return 'Could not send the message';
    }
    if (currentLanguage === 'spanish') {
      return 'No se pudo enviar el mensaje';
    }
  }

  if (infoCode === 'contact_form_invalid_fields_title') {
    if (currentLanguage === 'portuguese') {
      return 'Campos inválidos!';
    }
    if (currentLanguage === 'english') {
      return 'Invalid fields!';
    }
    if (currentLanguage === 'spanish') {
      return 'Campos inválidos!';
    }
  }

  if (infoCode === 'contact_form_invalid_fields_description') {
    if (currentLanguage === 'portuguese') {
      return 'Necessário preencher/selecionar todos os campos';
    }
    if (currentLanguage === 'english') {
      return 'It is necessary to fill/select all fields';
    }
    if (currentLanguage === 'spanish') {
      return 'Es necesario llenar/seleccionar todos los campos';
    }
  }

  if (infoCode === 'contact_agreement_info_01') {
    if (currentLanguage === 'portuguese') {
      return 'Li e aceito a ';
    }
    if (currentLanguage === 'english') {
      return 'I read and accept the ';
    }
    if (currentLanguage === 'spanish') {
      return 'Leo y acepto la ';
    }
  }

  if (infoCode === 'contact_agreement_info_privacy_policy') {
    if (currentLanguage === 'portuguese') {
      return 'Política de Privacidade';
    }
    if (currentLanguage === 'english') {
      return 'Privacy Policy';
    }
    if (currentLanguage === 'spanish') {
      return 'Política de Privacidad';
    }
  }

  if (infoCode === 'contact_agreement_info_02') {
    if (currentLanguage === 'portuguese') {
      return ' da Avícola Pato Branco';
    }
    if (currentLanguage === 'english') {
      return ' of Avícola Pato Branco';
    }
    if (currentLanguage === 'spanish') {
      return ' de Avícola Pato Branco';
    }
  }

  if (infoCode === 'contact_form_send_button') {
    if (currentLanguage === 'portuguese') {
      return 'Enviar';
    }
    if (currentLanguage === 'english') {
      return 'Send';
    }
    if (currentLanguage === 'spanish') {
      return 'Enviar';
    }
  }

  if (infoCode === 'footer_menu_title') {
    if (currentLanguage === 'portuguese') {
      return 'Menu';
    }
    if (currentLanguage === 'english') {
      return 'Menu';
    }
    if (currentLanguage === 'spanish') {
      return 'Menú';
    }
  }

  if (infoCode === 'footer_social_media_title') {
    if (currentLanguage === 'portuguese') {
      return 'Mídias Sociais';
    }
    if (currentLanguage === 'english') {
      return 'Social media';
    }
    if (currentLanguage === 'spanish') {
      return 'Redes sociales';
    }
  }

  if (infoCode === 'footer_customer_portal_title') {
    if (currentLanguage === 'portuguese') {
      return 'Portal do Cliente';
    }
    if (currentLanguage === 'english') {
      return 'Customer Portal';
    }
    if (currentLanguage === 'spanish') {
      return 'Portal del Cliente';
    }
  }

  if (infoCode === 'footer_reporting_channel_title') {
    if (currentLanguage === 'portuguese') {
      return 'Canal de Denúncias';
    }
    if (currentLanguage === 'english') {
      return 'Reporting Channel';
    }
    if (currentLanguage === 'spanish') {
      return 'Canal de Denuncia';
    }
  }

  if (infoCode === 'footer_localization_title') {
    if (currentLanguage === 'portuguese') {
      return 'Onde estamos?';
    }
    if (currentLanguage === 'english') {
      return 'Where are we?';
    }
    if (currentLanguage === 'spanish') {
      return '¿Donde estamos?';
    }
  }

  if (infoCode === 'footer_localization_info_01') {
    if (currentLanguage === 'portuguese') {
      return 'Av. Tupi, 5190 – Bairro Morumbi';
    }
    if (currentLanguage === 'english') {
      return 'Av. Tupi, 5190 – Bairro Morumbi';
    }
    if (currentLanguage === 'spanish') {
      return 'Av. Tupi, 5190 - Bairro Morumbi';
    }
  }

  if (infoCode === 'footer_localization_info_02') {
    if (currentLanguage === 'portuguese') {
      return 'CEP 85506-095 – Pato Branco PR';
    }
    if (currentLanguage === 'english') {
      return 'Zip Code 85506-095 – Pato Branco – Paraná – Brazil';
    }
    if (currentLanguage === 'spanish') {
      return 'CEP 85506-095 - Pato Branco PR';
    }
  }

  if (infoCode === 'footer_development_01') {
    if (currentLanguage === 'portuguese') {
      return '©2023 Desenvolvido por ';
    }
    if (currentLanguage === 'english') {
      return '©2023 Developed by ';
    }
    if (currentLanguage === 'spanish') {
      return '©2023 Desarrollado por ';
    }
  }

  if (infoCode === 'footer_development_02') {
    if (currentLanguage === 'portuguese') {
      return ' e ';
    }
    if (currentLanguage === 'english') {
      return ' and ';
    }
    if (currentLanguage === 'spanish') {
      return ' y ';
    }
  }

  if (infoCode === 'privacypolicy_main_title') {
    if (currentLanguage === 'portuguese') {
      return 'Política de Privacidade - Avícola Pato Branco Ltda.';
    }
    if (currentLanguage === 'english') {
      return 'Privacy Policy - Avícola Pato Branco Ltda.';
    }
    if (currentLanguage === 'spanish') {
      return 'Política de Privacidad - Avícola Pato Branco Ltda.';
    }
  }

  if (infoCode === 'privacypolicy_main_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Avícola Pato Branco Ltda., pessoa jurídica de direito privado, ' +
        'inscrita no CNPJ 79.865.366/0001-83 leva a sua privacidade a ' +
        'sério e zela pela segurança e proteção de dados de todos os ' +
        'seus clientes, parceiros, fornecedores e usuários do site ' +
        'domínio www.avicolapb.com.br e qualquer outro site ou ' +
        'aplicativo operado pela organização.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Avícola Pato Branco Ltda., a legal entity governed by ' +
        'private law, registered with CNPJ 79.865.366/0001-83 ' +
        'takes your privacy seriously and ensures the security ' +
        'and data protection of all its customers, partners, ' +
        'suppliers and users of the domain website ' +
        'www.avicolapb.com.br and any other website ' +
        'or application operated by the organization.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Avícola Pato Branco Ltda., persona jurídica de derecho ' +
        'privado, registrada en el CNPJ 79.865.366/0001-83, lleva ' +
        'la su privacidad en serio y vela per la seguridad y protección ' +
        'de datos de todos sus clientes, socios, proveedores y usuarios ' +
        'del sitio dominio www.avicolapb.com.br y cualquier otro sitio ' +
        'web o aplicación operada por la organización.'
      );
    }
  }

  if (infoCode === 'privacypolicy_main_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Esta Política de Privacidade destina-se a informá-lo sobre o ' +
        'modo como nós utilizamos e divulgamos informações coletadas ' +
        'em suas visitas ao nosso site institucional e em mensagens ' +
        'que trocamos com você.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'This Privacy Policy is intended to inform you about how ' +
        'we use and disclose information collected in your visits ' +
        'to our institutional website and in messages we exchange with you.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Esta Política de privacidad tiene como objetivo informarle ' +
        'sobre el modo cómo usamos y divulgamos informaciones recopilada ' +
        'en sus visitas a nuestro sitio web institucional y en los mensajes ' +
        'que intercambiamos contigo.'
      );
    }
  }

  if (infoCode === 'privacypolicy_main_text_03') {
    if (currentLanguage === 'portuguese') {
      return 'Esta Política de Privacidade aplica-se somente a informações coletadas por meio do site.';
    }
    if (currentLanguage === 'english') {
      return 'This Privacy Policy only applies to information collected through the website.';
    }
    if (currentLanguage === 'spanish') {
      return 'Esta Política de privacidad solo se aplica a la informaciones recopilada a través del sitio web.';
    }
  }

  if (infoCode === 'privacypolicy_main_note') {
    if (currentLanguage === 'portuguese') {
      return (
        'AO ACESSAR O SITE, ENVIAR COMUNICAÇÕES OU FORNECER QUALQUER ' +
        'TIPO DE DADO PESSOAL, VOCÊ DECLARA ESTAR CIENTE COM RELAÇÃO ' +
        'AOS TERMOS AQUI PREVISTOS E DE ACORDO COM A POLÍTICA DE ' +
        'PRIVACIDADE, A QUAL DESCREVE AS FINALIDADES E FORMAS DE ' +
        'TRATAMENTO DE SEUS DADOS PESSOAIS QUE VOCÊ DISPONIBILIZAR.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'BY ACCESSING THE SITE, SENDING COMMUNICATIONS OR PROVIDING ' +
        'ANY KIND OF PERSONAL DATA, YOU DECIDE TO BE AWARE OF THE ' +
        'TERMS SET FORTH HEREIN AND IN ACCORDANCE WITH THE PRIVACY ' +
        'POLICY, WHICH DESCRIBES THE PURPOSES AND WAYS OF PROCESSING ' +
        'YOUR PERSONAL DATA THAT YOU MAKE AVAILABLE.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'AL ACCEDER AL SITIO WEB, ENVIAR COMUNICACIONES O PROPORCIONAR ' +
        'CUALQUIER TIPO DE DATO PERSONAL, USTED DECLARA SER CONSCIENTE ' +
        'CON RELACIONE A LOS TÉRMINOS AQUÍ ESTABLECIDOS Y DE CONFORMIDAD ' +
        'CON LA POLÍTICA DE PRIVACIDAD, QUE DESCRIBE LAS FINALIDADES Y ' +
        'FORMAS DE TRATAMIENTO DE SUS DATOS PERSONALES QUE USTED PONE A DISPOSICIÓN.'
      );
    }
  }

  if (infoCode === 'privacypolicy_main_text_04') {
    if (currentLanguage === 'portuguese') {
      return (
        'Esta Política de Privacidade fornece uma visão geral de ' +
        'nossas práticas de privacidade e das escolhas que você ' +
        'pode fazer, bem como direitos que você pode exercer em ' +
        'relação aos Dados Pessoais tratados por nós. Se você ' +
        'tiver alguma dúvida sobre o uso de Dados Pessoais, ' +
        'entre em contato com privacidade@avicolapb.com.br ' +
        'ou +55 46 3220-7600.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'This Privacy Policy provides an overview of our privacy ' +
        'practices and the choices you can make, as well as the ' +
        'rights you can exercise in relation to Personal Data ' +
        'processed by us. If you have any questions about the ' +
        'use of Personal Data, please contact ' +
        'privacidade@avicolapb.com.br or +55 46 3220-7600.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Esta Política de privacidad proporciona una descripción ' +
        'general de nuestras prácticas de privacidad y las elecciones ' +
        'que puede hacer, así como los derechos que puede ejercer en ' +
        'relación con los Datos Personales que procesamos. Si tiene ' +
        'alguna duda sobre el uso de Datos Personales, comuníquese ' +
        'con privacidade@avicolapb.com.br o +55 46 3220-7600.'
      );
    }
  }

  if (infoCode === 'privacypolicy_main_text_05') {
    if (currentLanguage === 'portuguese') {
      return (
        'Além disso, a Política de Privacidade não se aplica a ' +
        'quaisquer aplicativos, sites ou recursos de mídia ' +
        'social de terceiros que possam ser oferecidos ou ' +
        'acessados por meio do site. O acesso a esses ' +
        'links fará com que você deixe o nosso site e ' +
        'poderá resultar na coleta ou compartilhamento ' +
        'de informações sobre você por terceiros. Nós não ' +
        'controlamos, endossamos ou fazemos quaisquer ' +
        'representações sobre sites de terceiros ou suas ' +
        'práticas de privacidade, que podem ser diferentes ' +
        'das nossas. Recomendamos que você revise a política ' +
        'de privacidade de qualquer site com o qual você interaja ' +
        'antes de permitir a coleta e o uso de seus Dados Pessoais.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'In addition, the Privacy Policy does not apply to any ' +
        'third-party apps, websites, or social media features ' +
        'that may be offered or accessed through the website. ' +
        'Accessing these links will cause you to leave our site ' +
        'and may result in the collection or sharing of information ' +
        'about you by third parties. We do not control, endorse or ' +
        'make any representations about third party websites or their ' +
        'privacy practices, which may differ from ours. We recommend ' +
        'that you review the privacy policy of any website you interact ' +
        'with before allowing the collection and use of your Personal Data.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Además, la Política de privacidad no se aplica a cualquier ' +
        'aplicación, sitios o funciones de redes sociales de terceros ' +
        'que se pueden ofrecer o acceder a través del sitio web. El ' +
        'acesso a estos enlaces hará com que abandone nuestro sitio y ' +
        'puedera resultar en la recopilación o el intercambio de información ' +
        'sobre usted por parte de terceros. No controlamos, respaldamos o ' +
        'hacemos cualquier representaciones sobre los sitios  de terceros ' +
        'o sus prácticas de privacidad, que pueden diferir de las nuestras. Le ' +
        'recomendamos que revise la política de privacidad de cualquier sitio web ' +
        'con el que interactúe antes de permitir la recopilación y el uso de sus Datos Personales.'
      );
    }
  }

  if (infoCode === 'privacypolicy_main_text_06') {
    if (currentLanguage === 'portuguese') {
      return (
        'Caso você nos envie Dados Pessoais referentes a ' +
        'outras pessoas físicas, você declara ter a competência ' +
        'para fazê-lo e declara ter obtido o consentimento necessário ' +
        'para autorizar o uso de tais informações nos termos desta ' +
        'Política de Privacidade.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'If you send us Personal Data concerning other individuals, you ' +
        'declare that you have the competence to do so and declare that ' +
        'you have obtained the necessary consent to authorize the use of ' +
        'such information under the terms of this Privacy Policy.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'En el caso que nos envía Datos Personales sobre otras personas ' +
        'fisicas, tu declaras que tiene la competencia para hacerlo y ' +
        'declara que ha obtenido el consentimiento necesario para autorizar ' +
        'el uso de tales informaciones en los términos de esta Política de Privacidad.'
      );
    }
  }

  if (infoCode === 'privacypolicy_definitions_title') {
    if (currentLanguage === 'portuguese') {
      return '1 - Definições';
    }
    if (currentLanguage === 'english') {
      return '1 - Definitions';
    }
    if (currentLanguage === 'spanish') {
      return '1. Definiciones';
    }
  }

  if (infoCode === 'privacypolicy_definitions_text_01') {
    if (currentLanguage === 'portuguese') {
      return 'Para os fins desta Política de Privacidade:';
    }
    if (currentLanguage === 'english') {
      return 'For the purposes of this Privacy Policy:';
    }
    if (currentLanguage === 'spanish') {
      return 'Para los fines de esta Política de Privacidad:';
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_a_index') {
    if (currentLanguage === 'portuguese') {
      return 'a)';
    }
    if (currentLanguage === 'english') {
      return 'a)';
    }
    if (currentLanguage === 'spanish') {
      return 'a)';
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_a_title') {
    if (currentLanguage === 'portuguese') {
      return ` "Dados Pessoais": `;
    }
    if (currentLanguage === 'english') {
      return ` "Personal Data": `;
    }
    if (currentLanguage === 'spanish') {
      return ` "Datos Personales": `;
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_a_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'significa qualquer informação que, direta ou indiretamente, ' +
        'identifique ou possa identificar uma pessoa natural, como ' +
        'por exemplo, nome, CPF, data de nascimento, endereço IP, dentre outros;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Means any information that, directly or indirectly, identifies ' +
        'or can identify a natural person, such as name, CPF, date of ' +
        'birth, IP address, among others;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'significa cualquier información que, directa o indirectamente, ' +
        'identifique o pueda identificar una persona natural, como por ' +
        'ejemplo, nombre, CPF, fecha de nacimiento, dirección IP, entre otros;'
      );
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_b_index') {
    if (currentLanguage === 'portuguese') {
      return 'b)';
    }
    if (currentLanguage === 'english') {
      return 'b)';
    }
    if (currentLanguage === 'spanish') {
      return 'b)';
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_b_title') {
    if (currentLanguage === 'portuguese') {
      return ` "Dados Pessoais Sensíveis": `;
    }
    if (currentLanguage === 'english') {
      return ` "Sensitive Personal Data": `;
    }
    if (currentLanguage === 'spanish') {
      return ` "Datos Personales Sensibles": `;
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_b_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'significa qualquer informação que revele, em relação a ' +
        'uma pessoa natural, origem racial ou étnica, convicção ' +
        'religiosa, opinião política, filiação a sindicato ou a ' +
        'organização de caráter religioso, filosófico ou político, ' +
        'dado referente à saúde ou à vida sexual, dado genético ou biométrico;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Means any information that reveals, in relation to a natural person, ' +
        'racial or ethnic origin, religious conviction, political opinion, ' +
        'membership of a trade union or organization of a religious, ' +
        'philosophical or political nature, data relating to health ' +
        'or sexual life, genetic data or biometric;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'significa cualquier información que revele, en relación a una ' +
        'persona natural, origen racial o étnico, convicción religiosa, ' +
        'opinión política, afiliación a un sindicato o a organización de ' +
        'carácter religioso, filosófico o político, dato relativo a salud o ' +
        'a vida sexual, dato genético o biométrico;'
      );
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_c_index') {
    if (currentLanguage === 'portuguese') {
      return 'c)';
    }
    if (currentLanguage === 'english') {
      return 'c)';
    }
    if (currentLanguage === 'spanish') {
      return 'c)';
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_c_title') {
    if (currentLanguage === 'portuguese') {
      return ` "Tratamento de Dados Pessoais": `;
    }
    if (currentLanguage === 'english') {
      return ` "Processing Personal Data": `;
    }
    if (currentLanguage === 'spanish') {
      return ` "Tratamiento de Datos Personales": `;
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_c_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'significa qualquer operação efetuada no âmbito dos ' +
        'Dados Pessoais, por meio de meios automáticos ou não, ' +
        'tal como a recolha, gravação, organização, estruturação, ' +
        'armazenamento, adaptação ou alteração, recuperação, consulta, ' +
        'utilização, divulgação por transmissão, disseminação ou, ' +
        'alternativamente, disponibilização, harmonização ou ' +
        'associação, restrição, eliminação ou destruição. Também ' +
        'é considerado Tratamento de Dados Pessoais qualquer outra ' +
        'operação prevista nos termos da legislação aplicável;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Means any operation carried out within the scope of Personal Data, ' +
        'through automatic means or not, such as the collection, recording, ' +
        'organization, structuring, storage, adaptation or alteration, ' +
        'retrieval, consultation, use, disclosure by transmission, ' +
        'dissemination or, alternatively, availability, harmonization ' +
        'or association, restriction, elimination or destruction. Any ' +
        'other operation provided for under the terms of applicable ' +
        'legislation is also considered Processing of Personal Data;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'significa cualquier operación realizada en el ámbito de los ' +
        'Datos Personales, a través de medios automáticos o no, tales ' +
        'como la recolección, grabaciòn, organización, estructuración, ' +
        'almacenamiento, adaptación o alteración, recuperación, consulta, ' +
        'uso, divulgación por transmisión, difusión o, alternativamente, ' +
        'disponibilizaciòn, armonización o asociación, restricción, ' +
        'eliminación o destrucción. También se considera Tratamiento ' +
        'de Datos Personales cualquier otra operación prevista en la legislación aplicable;'
      );
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_d_index') {
    if (currentLanguage === 'portuguese') {
      return 'd)';
    }
    if (currentLanguage === 'english') {
      return 'd)';
    }
    if (currentLanguage === 'spanish') {
      return 'd)';
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_d_title') {
    if (currentLanguage === 'portuguese') {
      return ` "Leis de Proteção de Dados": `;
    }
    if (currentLanguage === 'english') {
      return ` "Data Protection Laws": `;
    }
    if (currentLanguage === 'spanish') {
      return ` "Leyes de Protección de Datos": `;
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_d_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'significa todas as disposições legais que regulam o ' +
        'Tratamento de Dados Pessoais, incluindo, porém sem se ' +
        'limitar, a Lei nº 13.709/18, Lei Geral de Proteção de Dados Pessoais ("LGPD").'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Means all legal provisions that regulate the Processing of ' +
        'Personal Data, including, but not limited to, Law No. 13,709/18, ' +
        'General Law for the Protection of Personal Data ("LGPD").'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'significa todas las disposiciones legales que regulan el Tratamiento ' +
        'de Datos Personales, incluyendo, pero sin si limitar, la Ley N° 13.709/18, ' +
        'Ley General de Protección de Datos Personales ("LGPD").'
      );
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_e_index') {
    if (currentLanguage === 'portuguese') {
      return 'e)';
    }
    if (currentLanguage === 'english') {
      return 'e)';
    }
    if (currentLanguage === 'spanish') {
      return 'e)';
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_e_title') {
    if (currentLanguage === 'portuguese') {
      return ` "ANPD": `;
    }
    if (currentLanguage === 'english') {
      return ` "ANPD": `;
    }
    if (currentLanguage === 'spanish') {
      return ` "ANPD": `;
    }
  }

  if (infoCode === 'privacypolicy_definitions_item_e_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Autoridade Nacional de Proteção de dados, o órgão da ' +
        'administração pública federal responsável por zelar ' +
        'pela proteção de dados pessoais e por implementar e ' +
        'fiscalizar o cumprimento da LGPD no Brasil.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'National Data Protection Authority, the federal public ' +
        'administration body responsible for ensuring the protection ' +
        'of personal data and for implementing and monitoring compliance ' +
        'with the LGPD in Brazil.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Autoridad Nacional de Protección de Datos, el organo de la ' +
        'administración pública federal responsable por velar per la ' +
        'protección de los datos personales y por implementar y monitorear ' +
        'el cumplimiento de la LGPD en Brasil.'
      );
    }
  }

  if (infoCode === 'privacypolicy_personal_data_title') {
    if (currentLanguage === 'portuguese') {
      return '2 - Uso de Dados Pessoais';
    }
    if (currentLanguage === 'english') {
      return '2 - Use of Personal Data';
    }
    if (currentLanguage === 'spanish') {
      return '2 - Uso de Datos Personales';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Coletamos e usamos Dados Pessoais para gerenciar seu ' +
        'relacionamento conosco e melhor atendê-lo. Exemplos ' +
        'de como usamos os dados incluem:'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'We collect and use Personal Data to manage your relationship ' +
        'with us and better serve you. Examples of how we use data include:'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Recopilamos y usamos Datos personales para administrar ' +
        'su relación con nosotros y servirte mejor. Ejemplos de ' +
        'cómo usamos los datos incluyen:'
      );
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_01_index') {
    if (currentLanguage === 'portuguese') {
      return '1.';
    }
    if (currentLanguage === 'english') {
      return '1.';
    }
    if (currentLanguage === 'spanish') {
      return '1.';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_01_text') {
    if (currentLanguage === 'portuguese') {
      return 'Para confirmar ou corrigir as informações que temos sobre você;';
    }
    if (currentLanguage === 'english') {
      return 'To confirm or correct the information we hold about you;';
    }
    if (currentLanguage === 'spanish') {
      return 'Para confirmar o corregir la información que tenemos sobre usted;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_02_index') {
    if (currentLanguage === 'portuguese') {
      return '2.';
    }
    if (currentLanguage === 'english') {
      return '2.';
    }
    if (currentLanguage === 'spanish') {
      return '2.';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_02_text') {
    if (currentLanguage === 'portuguese') {
      return 'Para enviar informações que acreditamos ser do seu interesse;';
    }
    if (currentLanguage === 'english') {
      return 'To submit information that we believe may be of interest to you;';
    }
    if (currentLanguage === 'spanish') {
      return 'Para enviar informaciones que creemos que puede ser de su interés;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_03_index') {
    if (currentLanguage === 'portuguese') {
      return '3.';
    }
    if (currentLanguage === 'english') {
      return '3.';
    }
    if (currentLanguage === 'spanish') {
      return '3.';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_03_text') {
    if (currentLanguage === 'portuguese') {
      return 'Para enviar informações profissionais (currículo);';
    }
    if (currentLanguage === 'english') {
      return 'To send professional information (resume);';
    }
    if (currentLanguage === 'spanish') {
      return 'Para enviar informaciones profesionales (currículum);';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_04_index') {
    if (currentLanguage === 'portuguese') {
      return '4.';
    }
    if (currentLanguage === 'english') {
      return '4.';
    }
    if (currentLanguage === 'spanish') {
      return '4.';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_04_text') {
    if (currentLanguage === 'portuguese') {
      return 'Para enviar informações sobre atendimento de clientes e fornecedores;';
    }
    if (currentLanguage === 'english') {
      return 'To send customer and supplier service information;';
    }
    if (currentLanguage === 'spanish') {
      return 'Para enviar informaciones sobre asistencia de los clientes y proveedores;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_05_index') {
    if (currentLanguage === 'portuguese') {
      return '5.';
    }
    if (currentLanguage === 'english') {
      return '5.';
    }
    if (currentLanguage === 'spanish') {
      return '5.';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_05_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Para entrarmos em contato por um número de telefone ' +
        'e/ou endereço de e-mail fornecido, nos termos legais e ' +
        'para fins comerciais razoáveis.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'To contact you at a telephone number and/or email address ' +
        'provided, in accordance with law and for reasonable business purposes.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Para que entremos en contacto por un número de teléfono ' +
        'y/o dirección de correo electrónico proporcionados, de ' +
        'conformidad con la ley y para fines comerciales razonables.'
      );
    }
  }

  if (infoCode === 'privacypolicy_personal_data_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Além disso, os Dados Pessoais fornecidos também ' +
        'podem ser utilizados na forma que julgarmos necessária ou adequada:'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'In addition, the Personal Data provided may also be used in ' +
        'the way we deem necessary or appropriate:'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Además, los Datos Personales proporcionados también pueden ser ' +
        'utilizados en la forma que consideremos necesaria o adecuada:'
      );
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_a_index') {
    if (currentLanguage === 'portuguese') {
      return 'a)';
    }
    if (currentLanguage === 'english') {
      return 'a)';
    }
    if (currentLanguage === 'spanish') {
      return 'a)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_a_text') {
    if (currentLanguage === 'portuguese') {
      return 'nos termos das Leis de Proteção de Dados;';
    }
    if (currentLanguage === 'english') {
      return 'pursuant to the Data Protection Laws;';
    }
    if (currentLanguage === 'spanish') {
      return 'en los términos de las Leyes de Protección de Datos;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_b_index') {
    if (currentLanguage === 'portuguese') {
      return 'b)';
    }
    if (currentLanguage === 'english') {
      return 'b)';
    }
    if (currentLanguage === 'spanish') {
      return 'b)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_b_text') {
    if (currentLanguage === 'portuguese') {
      return 'para atender exigências de processo judicial;';
    }
    if (currentLanguage === 'english') {
      return 'to meet legal process requirements;';
    }
    if (currentLanguage === 'spanish') {
      return 'para atender requisitos del proceso judicial;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_c_index') {
    if (currentLanguage === 'portuguese') {
      return 'c)';
    }
    if (currentLanguage === 'english') {
      return 'c)';
    }
    if (currentLanguage === 'spanish') {
      return 'c)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_c_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'para cumprir decisão judicial, decisão regulatória ' +
        'ou decisão de autoridades competentes, incluindo ' +
        'autoridades fora do país de residência;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'to comply with a court decision, regulatory decision ' +
        'or decision of competent authorities, including authorities ' +
        'outside the country of residence;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'para cumplir decisión judicial, decisión reglamentaria ' +
        'o decisión de las autoridades competentes, incluidas las ' +
        'autoridades fuera del país de residencia;'
      );
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_d_index') {
    if (currentLanguage === 'portuguese') {
      return 'd)';
    }
    if (currentLanguage === 'english') {
      return 'd)';
    }
    if (currentLanguage === 'spanish') {
      return 'd)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_d_text') {
    if (currentLanguage === 'portuguese') {
      return 'para aplicar nossos Termos e Condições de Uso;';
    }
    if (currentLanguage === 'english') {
      return 'to enforce our Terms and Conditions of Use;';
    }
    if (currentLanguage === 'spanish') {
      return 'para aplicar nuestros Términos y Condiciones de Uso;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_e_index') {
    if (currentLanguage === 'portuguese') {
      return 'e)';
    }
    if (currentLanguage === 'english') {
      return 'e)';
    }
    if (currentLanguage === 'spanish') {
      return 'e)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_e_text') {
    if (currentLanguage === 'portuguese') {
      return 'para proteger nossas operações;';
    }
    if (currentLanguage === 'english') {
      return 'to protect our operations;';
    }
    if (currentLanguage === 'spanish') {
      return 'para proteger nuestras operaciones;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_f_index') {
    if (currentLanguage === 'portuguese') {
      return 'f)';
    }
    if (currentLanguage === 'english') {
      return 'f)';
    }
    if (currentLanguage === 'spanish') {
      return 'f)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_f_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'para proteger a privacidade e os direitos de todos os ' +
        'envolvidos no tratamento de dados, direta ou indiretamente;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'to protect the privacy and rights of all those involved ' +
        'in data processing, directly or indirectly;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'para proteger la privacidad y los derechos de todos los ' +
        'involucrados en el tratamiento de datos, directa o indirectamente;'
      );
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_g_index') {
    if (currentLanguage === 'portuguese') {
      return 'g)';
    }
    if (currentLanguage === 'english') {
      return 'g)';
    }
    if (currentLanguage === 'spanish') {
      return 'g)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_g_text') {
    if (currentLanguage === 'portuguese') {
      return 'para detectar e prevenir fraudes;';
    }
    if (currentLanguage === 'english') {
      return 'to detect and prevent fraud;';
    }
    if (currentLanguage === 'spanish') {
      return 'para detectar y prevenir fraudes;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_h_index') {
    if (currentLanguage === 'portuguese') {
      return 'h)';
    }
    if (currentLanguage === 'english') {
      return 'h)';
    }
    if (currentLanguage === 'spanish') {
      return 'h)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_h_text') {
    if (currentLanguage === 'portuguese') {
      return 'permitir-nos usar as ações disponíveis ou limitar danos que venhamos a sofrer; e';
    }
    if (currentLanguage === 'english') {
      return 'allow us to use available remedies or limit damages we may suffer; and';
    }
    if (currentLanguage === 'spanish') {
      return 'permitirnos utilizar los recursos disponibles o limitar los daños que podamos sufrir;';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_i_index') {
    if (currentLanguage === 'portuguese') {
      return 'i)';
    }
    if (currentLanguage === 'english') {
      return 'i)';
    }
    if (currentLanguage === 'spanish') {
      return 'i)';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_item_i_text') {
    if (currentLanguage === 'portuguese') {
      return 'de outros modos permitidos por lei.';
    }
    if (currentLanguage === 'english') {
      return 'in other ways permitted by law.';
    }
    if (currentLanguage === 'spanish') {
      return 'de otras formas permitidas por la ley.';
    }
  }

  if (infoCode === 'privacypolicy_non_provided_personal_data_title') {
    if (currentLanguage === 'portuguese') {
      return '3 - Não fornecimento de Dados Pessoais';
    }
    if (currentLanguage === 'english') {
      return '3 - Failure to provide Personal Data';
    }
    if (currentLanguage === 'spanish') {
      return '3 - No Proporcionamos Datos Personales';
    }
  }

  if (infoCode === 'privacypolicy_non_provided_personal_data_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Não há obrigatoriedade em compartilhar os Dados Pessoais que ' +
        'solicitamos. No entanto, se você optar por não os compartilhar, ' +
        'em alguns casos, não poderemos fornecer a você acesso completo ao ' +
        'atendimento, alguns recursos especializados ou sermos capazes de ' +
        'prestar a assistência necessária.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'There is no obligation to share the Personal Data we request. However, ' +
        'if you choose not to share them, in some cases we may not be able to ' +
        'provide you with full access to service, some specialized resources or ' +
        'be able to provide you with the assistance you need.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'No hay obligatoriedad en compartir los Datos Personales que ' +
        'solicitamos. Aunque si tu elige no compartirlos, en algunos ' +
        'casos, no podremos ofrecerte acceso completo al servicio, ' +
        'algunos recursos especializados o sermos capaces de dar la ' +
        'asistencia necesaria.'
      );
    }
  }

  if (infoCode === 'privacypolicy_collected_data_title') {
    if (currentLanguage === 'portuguese') {
      return '4 - Dados coletados';
    }
    if (currentLanguage === 'english') {
      return '4 - Data collected';
    }
    if (currentLanguage === 'spanish') {
      return '4 - Datos recogidos';
    }
  }

  if (infoCode === 'privacypolicy_collected_data_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'O público em geral poderá navegar no site sem necessidade de ' +
        'qualquer cadastro e envio de Dados Pessoais. No entanto, algumas ' +
        'das funcionalidades do site poderão depender de cadastro e envio de ' +
        'Dados Pessoais para viabilizar atendimento oferecido por nós.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        `The general public will be able to browse the site without any ` +
        `registration and submission of Personal Data. However, some of ` +
        `the site's features may depend on registration and submission of ` +
        `Personal Data to enable the service offered by us.`
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'El público en general podrá navegar por el sitio sin necesidad ' +
        'de cualquier registro y envío de Datos Personales. Sin embargo, ' +
        'algunas de las características del sitio pueden depender del ' +
        'registro y envío de Datos Personales para habilitar el servicio ' +
        'ofrecido por nosostro.'
      );
    }
  }

  if (infoCode === 'privacypolicy_collected_data_text_02') {
    if (currentLanguage === 'portuguese') {
      return 'No site, nós podemos coletar:';
    }
    if (currentLanguage === 'english') {
      return 'On the website, we may collect:';
    }
    if (currentLanguage === 'spanish') {
      return 'En el sitio web, podemos recopilar:';
    }
  }

  if (infoCode === 'privacypolicy_collected_data_item_01_index') {
    if (currentLanguage === 'portuguese') {
      return '1.';
    }
    if (currentLanguage === 'english') {
      return '1.';
    }
    if (currentLanguage === 'spanish') {
      return '1.';
    }
  }

  if (infoCode === 'privacypolicy_collected_data_item_01_text') {
    if (currentLanguage === 'portuguese') {
      return 'Dados de contato: nome, sobrenome, número de telefone e endereço de e-mail;';
    }
    if (currentLanguage === 'english') {
      return 'Contact details: first name, last name, telephone number and email address;';
    }
    if (currentLanguage === 'spanish') {
      return 'Datos de contacto: nombre, apellido, número de teléfono y dirección de correo electrónico;';
    }
  }

  if (infoCode === 'privacypolicy_collected_data_item_02_index') {
    if (currentLanguage === 'portuguese') {
      return '2.';
    }
    if (currentLanguage === 'english') {
      return '2.';
    }
    if (currentLanguage === 'spanish') {
      return '2.';
    }
  }

  if (infoCode === 'privacypolicy_collected_data_item_02_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Informações enviadas: informações que você envia em um de nossos ' +
        'formulários (currículo, dúvidas, reclamações, sugestões, críticas, ' +
        'elogios, pedidos de contato, informações de maturidade de processos, etc);'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Information sent: information you send in one of our forms ' +
        '(resume, questions, complaints, suggestions, criticisms, ' +
        'compliments, contact requests, process maturity information, etc.);'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Informaciones enviadas: informaciones que envías en uno de nuestros ' +
        'formularios (currículum, dudas, quejas, sugerencias, críticas, elogios, ' +
        'solicitudes de contacto, informaciones de madurez del procesos, etc.);'
      );
    }
  }

  if (infoCode === 'privacypolicy_collected_data_item_03_index') {
    if (currentLanguage === 'portuguese') {
      return '3.';
    }
    if (currentLanguage === 'english') {
      return '3.';
    }
    if (currentLanguage === 'spanish') {
      return '3.';
    }
  }

  if (infoCode === 'privacypolicy_collected_data_item_03_text') {
    if (currentLanguage === 'portuguese') {
      return 'Preferências: informações sobre suas preferências e interesses em relação ao atendimento;';
    }
    if (currentLanguage === 'english') {
      return 'Preferences: information about your preferences and interests in relation to the service;';
    }
    if (currentLanguage === 'spanish') {
      return 'Preferencias: informaciones sobre sus preferencias e intereses en relación con el servicio;';
    }
  }

  if (infoCode === 'privacypolicy_collected_data_item_04_index') {
    if (currentLanguage === 'portuguese') {
      return '4.';
    }
    if (currentLanguage === 'english') {
      return '4.';
    }
    if (currentLanguage === 'spanish') {
      return '4.';
    }
  }

  if (infoCode === 'privacypolicy_collected_data_item_04_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Dados de navegação no site: informações sobre suas visitas e atividades, ' +
        'incluindo o conteúdo (e quaisquer anúncios) com os quais você visualiza e ' +
        'interage, informações sobre o navegador e o dispositivo que você está usando, ' +
        'seu endereço IP, sua localização, o endereço do site a partir do qual você chegou. ' +
        'Algumas dessas informações são coletadas usando nossas Ferramentas de Coleta ' +
        'Automática de Dados, que incluem cookies, web beacons, pixel tags ou outras ' +
        'tecnologias, além de links da web incorporados. Para saber mais, leia como ' +
        'nós usamos Ferramentas de Coleta Automática de Dados na nossa política de cookies;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Website browsing data: information about your visits and ' +
        'activities, including the content (and any advertisements) ' +
        'you view and interact with, information about the browser ' +
        'and device you are using, your IP address, your location, ' +
        'the address of the site from which you arrived. Some of this ' +
        'information is collected using our Automatic Data Collection ' +
        'Tools, which include cookies, web beacons, pixel tags or other ' +
        'technologies, as well as embedded web links. To learn more, read ' +
        'how we use Automatic Data Collection Tools in our cookie policy;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Datos de navegación del sitio: informaciones sobre sus visitas y actividades, ' +
        'incluido el contenido (y cualquier anuncios) con los cuales visualizas y ' +
        'interactúa, informaciones sobre el navegador y el dispositivo que estás ' +
        'usando, su dirección IP, su ubicación, la dirección del sitio web a partir ' +
        'del cual llegaste. Algunas de estas informaciones se recopila utilizando ' +
        'nuestras herramientas de recopilación automática de datos, que incluyen ' +
        'cookies, web beacons, pixel tags o otras tecnologías, además de enlaces ' +
        'de la web integrados. Para saber más, lea cómo usamos las herramientas de ' +
        'recopilación automática de datos en nuestra política de cookies;'
      );
    }
  }

  if (infoCode === 'privacypolicy_collected_data_highlighted_text') {
    if (currentLanguage === 'portuguese') {
      return 'Nós não coletamos Dados Pessoais Sensíveis.';
    }
    if (currentLanguage === 'english') {
      return 'We do not collect Sensitive Personal Data.';
    }
    if (currentLanguage === 'spanish') {
      return 'No recopilamos Datos Personales confidenciales.';
    }
  }

  if (infoCode === 'privacypolicy_sharing_p_d_third_parties_title') {
    if (currentLanguage === 'portuguese') {
      return '5 - Compartilhamento de Dados Pessoais com terceiros';
    }
    if (currentLanguage === 'english') {
      return '5 - Sharing Personal Data with Third Parties';
    }
    if (currentLanguage === 'spanish') {
      return '5 – El intercambio de Datos Personales con terceros';
    }
  }

  if (infoCode === 'privacypolicy_sharing_p_d_third_parties_text_01') {
    if (currentLanguage === 'portuguese') {
      return 'Nós poderemos compartilhar seus Dados Pessoais:';
    }
    if (currentLanguage === 'english') {
      return 'We may share your Personal Data:';
    }
    if (currentLanguage === 'spanish') {
      return 'Nosotros podremos compartir sus datos personales:';
    }
  }

  if (infoCode === 'privacypolicy_sharing_p_d_third_parties_item_01_index') {
    if (currentLanguage === 'portuguese') {
      return '1.';
    }
    if (currentLanguage === 'english') {
      return '1.';
    }
    if (currentLanguage === 'spanish') {
      return '1.';
    }
  }

  if (infoCode === 'privacypolicy_sharing_p_d_third_parties_item_01_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Com provedores de serviços ou parceiros para gerenciar ou suportar ' +
        'certos aspectos de nossas operações em nosso nome. Esses provedores ' +
        'de serviços ou parceiros podem estar localizados em outros locais ' +
        'globais, incluindo servidores para homologação e produção, e ' +
        'prestadores de serviços de hospedagem e armazenamento de dados, ' +
        'atendimento de pedidos, e serviços de TI, por exemplo;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'With service providers or partners to manage or support certain ' +
        'aspects of our operations on our behalf. These service providers ' +
        'or partners may be located in other global locations, including ' +
        'servers for approval and production, and providers of hosting and ' +
        'data storage, order fulfillment, and IT services, for example;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Con proveedores de servicios o socios para administrar o respaldar ' +
        'ciertos aspectos de nuestras operaciones en nuestro nombre. Estos ' +
        'proveedores de servicios o socios pueden estar ubicados en otras ' +
        'ubicaciones globales, incluido servidores para aprobación y ' +
        'producción, y proveedores de servicios de alojamiento y ' +
        'almacenamiento de datos, cumplimiento de pedidos y ' +
        'servicios de TI, por ejemplo;'
      );
    }
  }

  if (infoCode === 'privacypolicy_sharing_p_d_third_parties_item_02_index') {
    if (currentLanguage === 'portuguese') {
      return '2.';
    }
    if (currentLanguage === 'english') {
      return '2.';
    }
    if (currentLanguage === 'spanish') {
      return '2.';
    }
  }

  if (infoCode === 'privacypolicy_sharing_p_d_third_parties_item_02_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'Com prestadores de serviços de recrutamento, em casos de disponibilização ' +
        'de currículos com a finalidade de possíveis contratações internas, tratando ' +
        'os dados nas medidas técnicas e administrativas aptas a proteger os dados do titular.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'With recruitment service providers, in cases where CVs are made available ' +
        'for the purpose of possible internal hiring, processing the data in the ' +
        'technical and administrative measures capable of protecting the data of the subject.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Con proveedores de servicios de contratación, en casos de disponibilización ' +
        'de currículos con el fin de posibles contrataciones internas, tratar los ' +
        'datos en medidas técnicas y administrativas capaces de proteger los datos del titular.'
      );
    }
  }

  if (infoCode === 'privacypolicy_international_data_transfers_title') {
    if (currentLanguage === 'portuguese') {
      return '6 - Transferências internacionais de dados';
    }
    if (currentLanguage === 'english') {
      return '6 - International data transfers';
    }
    if (currentLanguage === 'spanish') {
      return '6 - Transferencias internacionales de datos';
    }
  }

  if (infoCode === 'privacypolicy_international_data_transfers_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Dados encaminhados e coletados através do nosso site podem ter ' +
        'suas informações trafegadas por servidores distribuídos em outros países.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Data sent and collected through our website may have your information ' +
        'transferred by servers distributed in other countries.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Datos enviados y recopilados a través de nuestro sitio web pueden ' +
        'tener sus informaciones traficadas por servidores distribuidos en otros países.'
      );
    }
  }

  if (infoCode === 'privacypolicy_user_rights_title') {
    if (currentLanguage === 'portuguese') {
      return '7 - Direitos do Usuário';
    }
    if (currentLanguage === 'english') {
      return '7 - User Rights';
    }
    if (currentLanguage === 'spanish') {
      return '7 - Derechos del Usuario';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_text_01') {
    if (currentLanguage === 'portuguese') {
      return 'Você pode, a qualquer momento, requerer:';
    }
    if (currentLanguage === 'english') {
      return 'You may, at any time, request:';
    }
    if (currentLanguage === 'spanish') {
      return 'Usted podrá, en cualquier momento, solicitar:';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_a_index') {
    if (currentLanguage === 'portuguese') {
      return 'a.';
    }
    if (currentLanguage === 'english') {
      return 'a.';
    }
    if (currentLanguage === 'spanish') {
      return 'a.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_a_text') {
    if (currentLanguage === 'portuguese') {
      return 'confirmação de que seus Dados Pessoais estão sendo tratados;';
    }
    if (currentLanguage === 'english') {
      return 'confirmation that your Personal Data is being processed;';
    }
    if (currentLanguage === 'spanish') {
      return 'confirmación de que sus Datos Personales están siendo procesados;';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_b_index') {
    if (currentLanguage === 'portuguese') {
      return 'b.';
    }
    if (currentLanguage === 'english') {
      return 'b.';
    }
    if (currentLanguage === 'spanish') {
      return 'b.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_b_text') {
    if (currentLanguage === 'portuguese') {
      return 'acesso aos seus Dados Pessoais;';
    }
    if (currentLanguage === 'english') {
      return 'access to your Personal Data;';
    }
    if (currentLanguage === 'spanish') {
      return 'acceso a sus Datos Personales;';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_c_index') {
    if (currentLanguage === 'portuguese') {
      return 'c.';
    }
    if (currentLanguage === 'english') {
      return 'c.';
    }
    if (currentLanguage === 'spanish') {
      return 'c.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_c_text') {
    if (currentLanguage === 'portuguese') {
      return 'correções a dados incompletos, inexatos ou desatualizados;';
    }
    if (currentLanguage === 'english') {
      return 'corrections to incomplete, inaccurate or outdated data;';
    }
    if (currentLanguage === 'spanish') {
      return 'correcciones de datos incompletos, inexactos o desactualizados;';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_d_index') {
    if (currentLanguage === 'portuguese') {
      return 'd.';
    }
    if (currentLanguage === 'english') {
      return 'd.';
    }
    if (currentLanguage === 'spanish') {
      return 'd.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_d_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'anonimização, bloqueio ou eliminação de dados desnecessários, ' +
        'excessivos ou tratados em desconformidade com o disposto em lei;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'anonymization, blocking or elimination of unnecessary, excessive ' +
        'or processed data in violation of the law;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'anonimización, bloqueo o eliminación de datos innecesarios, ' +
        'excesivos o procesados ​​en disconformidad con las disposiciones de la ley;'
      );
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_e_index') {
    if (currentLanguage === 'portuguese') {
      return 'e.';
    }
    if (currentLanguage === 'english') {
      return 'e.';
    }
    if (currentLanguage === 'spanish') {
      return 'e.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_e_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'portabilidade de Dados Pessoais a outro prestador de serviços, ' +
        'contanto que isso não afete nossos segredos industriais e comerciais;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'portability of Personal Data to another service provider, as ' +
        'long as this does not affect our industrial and trade secrets;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'portabilidad de Datos Personales a otro proveedor de servicios, ' +
        'siempre que eso no afecte nuestros secretos industriales y comerciales;'
      );
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_f_index') {
    if (currentLanguage === 'portuguese') {
      return 'f.';
    }
    if (currentLanguage === 'english') {
      return 'f.';
    }
    if (currentLanguage === 'spanish') {
      return 'f.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_f_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'eliminação de Dados Pessoais tratados com seu ' +
        'consentimento, na medida do permitido em lei;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'deletion of Personal Data processed with your ' +
        'consent, to the extent permitted by law;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'eliminaciones de Datos Personales procesados ​​con ' +
        'su consentimiento, en la medida en que lo permita la ley;'
      );
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_g_index') {
    if (currentLanguage === 'portuguese') {
      return 'g.';
    }
    if (currentLanguage === 'english') {
      return 'g.';
    }
    if (currentLanguage === 'spanish') {
      return 'g.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_g_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'informações sobre as entidades às quais seus ' +
        'Dados Pessoais tenham sido compartilhados;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'information about the entities to which your ' +
        'Personal Data has been shared;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'informaciones sobre las entidades cuáles sus ' +
        'Datos Personales han sido compartidos;'
      );
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_h_index') {
    if (currentLanguage === 'portuguese') {
      return 'h.';
    }
    if (currentLanguage === 'english') {
      return 'h.';
    }
    if (currentLanguage === 'spanish') {
      return 'h.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_h_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'informações sobre a possibilidade de não fornecer o ' +
        'consentimento e sobre as consequências da negativa; e'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'information about the possibility of not providing ' +
        'consent and about the consequences of denial; and'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'informaciones sobre la posibilidad de no dar el ' +
        'consentimiento y sobre las consecuencias de la negación; y'
      );
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_i_index') {
    if (currentLanguage === 'portuguese') {
      return 'i.';
    }
    if (currentLanguage === 'english') {
      return 'i.';
    }
    if (currentLanguage === 'spanish') {
      return 'i.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_item_i_text') {
    if (currentLanguage === 'portuguese') {
      return 'revogação do consentimento.';
    }
    if (currentLanguage === 'english') {
      return 'revocation of consent.';
    }
    if (currentLanguage === 'spanish') {
      return 'revocación del consentimiento.';
    }
  }

  if (infoCode === 'privacypolicy_user_rights_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Os seus pedidos serão tratados com especial cuidado de forma ' +
        'que possamos assegurar a eficácia dos seus direitos. Poderá lhe ' +
        'ser solicitado que faça prova da sua identidade de modo a assegurar ' +
        'que a partilha dos Dados Pessoais é apenas feita com o seu titular.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Your requests will be treated with special care so that we ' +
        'can ensure the effectiveness of your rights. You may be asked ' +
        'to prove your identity in order to ensure that Personal Data is ' +
        'only shared with the holder.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Sus solicitudes serán tratadas con especial cuidado para que ' +
        'podamos garantizar la efectividad de sus derechos. Es posible ' +
        'que se le solicite que pruebe su identidade de modo para ' +
        'asegurar que los Datos Personales solo se compartan con el titular.'
      );
    }
  }

  if (infoCode === 'privacypolicy_user_rights_text_03') {
    if (currentLanguage === 'portuguese') {
      return (
        'Você deverá ter em mente que, em certos casos ' +
        '(por exemplo, devido a requisitos legais), o ' +
        'seu pedido poderá não ser imediatamente satisfeito, ' +
        'além de que nós poderemos não conseguir atendê-lo ' +
        'por conta de cumprimento de obrigações legais.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'You should keep in mind that in certain cases ' +
        '(for example, due to legal requirements), your ' +
        'order may not be immediately fulfilled, and we ' +
        'may not be able to fulfill it due to legal obligations.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Usted deberá tener en mente que, en ciertos casos (por ' +
        'ejemplo, debido a requisitos legales), el suyo pedido ' +
        'puedera no ser imediatamente satisfecho, además de que ' +
        'nosotros podremos no conseguir servirte debido al ' +
        'cumplimiento de obligaciones legales.'
      );
    }
  }

  if (infoCode === 'privacypolicy_user_rights_text_04') {
    if (currentLanguage === 'portuguese') {
      return (
        'A Avícola Pato Branco Ltda. responderá às solicitações ' +
        'em prazo coerente com o teor de seu conteúdo, sendo que ' +
        'os requerimentos mais complexos terão um prazo mais amplo.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Avícola Pato Branco Ltda. will respond to requests within a ' +
        'period consistent with the content of its content, with more ' +
        'complex requests having a longer period.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'A Avícola Pato Branco Ltda. responderá a las solicitudes ' +
        'en un plazo coherente con la temática de su contenido, ' +
        'siendo que las solicitudes más complejas tendrá un plazo más amplio.'
      );
    }
  }

  if (infoCode === 'privacypolicy_personal_data_security_title') {
    if (currentLanguage === 'portuguese') {
      return '8 - Segurança dos Dados Pessoais';
    }
    if (currentLanguage === 'english') {
      return '8 - Security of Personal Data';
    }
    if (currentLanguage === 'spanish') {
      return '8 - Seguridad de los Datos Personales';
    }
  }

  if (infoCode === 'privacypolicy_personal_data_security_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Buscamos adotar as medidas técnicas e organizacionais ' +
        'previstas pelas Leis de Proteção de Dados adequadas para ' +
        'proteção dos Dados Pessoais na nossa organização. Infelizmente, ' +
        'nenhuma transmissão ou sistema de armazenamento de dados tem a ' +
        'garantia de serem 100% seguros. Caso tenha motivos para acreditar ' +
        'que sua interação conosco tenha deixado de ser segura, favor nos ' +
        'notificar imediatamente através dos nossos canais de resposta a ' +
        'incidente: privacidade@avicolapb.com.br ou +55 46 3220-7600.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'We seek to adopt the technical and organizational measures ' +
        'provided for by the Data Protection Laws appropriate for the ' +
        'protection of Personal Data in our organization. Unfortunately, ' +
        'no data transmission or storage system is guaranteed to be 100% ' +
        'secure. If you have reason to believe that your interaction with ' +
        'us is no longer secure, please notify us immediately through our ' +
        'incident response channels: privacidade@avicolapb.com.br or ' +
        '+55 46 3220-7600.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Procuramos adoptar las medidas técnicas y organizativas previstas ' +
        'por las Leyes de Protección de Datos adecuadas para la protección ' +
        'de Datos Personales en nuestra organización. Desafortunadamente, ' +
        'ninguna transmisión o sistema de almacenamiento de datos tene la ' +
        'garantia de ser 100% seguros. Si tiene motivos para creer que su ' +
        'interacción con nosotros ha dejado de ser segura, notifíquenos de ' +
        'inmediato a través de nuestros canales de respuesta a incidentes: ' +
        'privacidade@avicolapb.com.br o +55 46 3220-7600.'
      );
    }
  }

  if (infoCode === 'privacypolicy_links_other_websites_social_networks_title') {
    if (currentLanguage === 'portuguese') {
      return '9 - Links de hipertexto para outros sites e redes sociais';
    }
    if (currentLanguage === 'english') {
      return '9 - Hypertext links to other websites and social networks';
    }
    if (currentLanguage === 'spanish') {
      return '9 - Enlaces  de hipertexto para otros sitios web y redes sociales';
    }
  }

  if (
    infoCode === 'privacypolicy_links_other_websites_social_networks_text_01'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'O site poderá, de tempos em tempos, conter links de hipertexto ' +
        'que redirecionem você para nossas redes sociais. Se você clicar ' +
        'em um desses links para qualquer uma dessas redes sociais, ' +
        'lembre-se que cada site possui as suas próprias práticas ' +
        'de privacidade e que não somos responsáveis por essas ' +
        'políticas. Consulte as referidas políticas antes de ' +
        'enviar quaisquer Dados Pessoais para esses sites.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'The website may, from time to time, contain hypertext links ' +
        'that redirect you to our social networks. If you click on one ' +
        'of these links to any of these social networks, please remember ' +
        'that each site has its own privacy practices and that we are not ' +
        'responsible for those policies. Please consult those policies before ' +
        'submitting any Personal Data to these sites.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'El sitio web puede, de vez en cuando, contener enlaces ' +
        'de hipertexto que lo redirigen a nuestras redes sociales. ' +
        'Si hace clic en uno de estos enlaces para cualquier una de ' +
        'estas redes sociales, recuerde que cada sitio tiene sus propias ' +
        'prácticas de privacidad y que no somos responsables por estas ' +
        'políticas. Consulte esas políticas antes de enviar datos ' +
        'personales a estos sitios.'
      );
    }
  }

  if (
    infoCode === 'privacypolicy_links_other_websites_social_networks_text_02'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Não nos responsabilizamos pelas políticas e práticas de coleta, ' +
        'uso e divulgação (incluindo práticas de proteção de dados) de ' +
        'outras organizações, tais como Facebook (Grupo Meta), Apple, ' +
        'Google, Microsoft, ou de qualquer outro desenvolvedor de ' +
        'software ou provedor de aplicativo, loja de mídia social, ' +
        'sistema operacional, prestador de serviços de internet ' +
        'sem fio ou fabricante de dispositivos, incluindo todos ' +
        'os Dados Pessoais que divulgar para outras organizações ' +
        'por meio dos aplicativos, relacionadas a tais aplicativos, ' +
        'ou publicadas em nossas páginas em mídias sociais. Nós ' +
        'recomendamos que você se informe sobre a política de ' +
        'privacidade de cada site visitado ou de cada prestador ' +
        'de serviço utilizado.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'We are not responsible for the collection, use and disclosure ' +
        'policies and practices (including data protection practices) of ' +
        'other organizations, such as Facebook (Grupo Meta), Apple, Google, ' +
        'Microsoft, or any other software developer or application provider, ' +
        'social media store, operating system, wireless internet service provider ' +
        'or device manufacturer, including all Personal Data you disclose to other ' +
        'organizations through the apps, in connection with such apps, or posted on ' +
        'our social media pages. We recommend that you inform yourself about the ' +
        'privacy policy of each website you visit or each service provider you use.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'No somos responsables de las políticas y prácticas de ' +
        'recopilación, uso y divulgación (incluidas las prácticas ' +
        'de protección de datos) de otras organizaciones, tales como ' +
        'Facebook (Grupo Meta), Apple, Google, Microsoft o de cualquier ' +
        'otro desarrollador de software o proveedor de aplicaciones, tienda ' +
        'de redes sociales, sistema operativo, proveedor de servicios de Internet ' +
        'sin hilo o fabricante del dispositivos, incluido todos los Datos Personales ' +
        'que divulga a otras organizaciones a través de las aplicaciones, relacionadas ' +
        'con tales aplicaciones o publicadas en nuestras páginas de redes sociales. Le ' +
        'recomendamos que se informe sobre la política de privacidad de cada sitio web ' +
        'que visite o de cada proveedor de servicios que utilice.'
      );
    }
  }

  if (infoCode === 'privacypolicy_contact_information_title') {
    if (currentLanguage === 'portuguese') {
      return '10 - Informações de Contato';
    }
    if (currentLanguage === 'english') {
      return '10 - Contact Information';
    }
    if (currentLanguage === 'spanish') {
      return '10 - Informaciones de Contacto';
    }
  }

  if (infoCode === 'privacypolicy_contact_information_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Com o propósito de possibilitar a comunicação com a ' +
        'Avícola Pato Branco, disponibilizamos o formulário de ' +
        'contato para realizar tal função, onde o mesmo realiza a ' +
        'coleta de seu nome, telefone, e-mail, possíveis documentos ' +
        'anexados, entre outras informações. São utilizados protocolos ' +
        'rígidos de segurança e confiabilidade, para armazenar todas as ' +
        'informações fornecidas pelo usuário.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'In order to enable communication with Avícola Pato Branco, ' +
        'we provide the contact form to perform this function, where ' +
        'it collects your name, telephone number, e-mail, possible ' +
        'attached documents, among other information. Strict security ' +
        'and reliability protocols are used to store all information ' +
        'provided by the user.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Com el proprosito de permitir la comunicación com la Avícola ' +
        'Pato Branco, proporcionamos el formulario de contacto para ' +
        'realizar esta función, donde lo mismo realiza la colecta de ' +
        'su nombre, número de teléfono, correo electrónico, posibles ' +
        'documentos adjuntos, entre otras informaciones. Se utilizan ' +
        'estrictos protocolos de seguridad y fiabilidad para almacenar ' +
        'todas las informaciones proporcionadas por el usuario.'
      );
    }
  }

  if (infoCode === 'privacypolicy_approval_title') {
    if (currentLanguage === 'portuguese') {
      return '11 - Aprovação';
    }
    if (currentLanguage === 'english') {
      return '11 - Approval';
    }
    if (currentLanguage === 'spanish') {
      return '11 – Aprobación';
    }
  }

  if (infoCode === 'privacypolicy_approval_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Esta política deverá ser aprovada pelo Comitê de Segurança da ' +
        'Informação e Respostas a Incidentes (CSIRI) e diretoria da Avícola Pato Branco.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'This policy must be approved by the Information Security and ' +
        'Incident Response Committee (CSIRI) and the board of Avícola Pato Branco.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Esta política deberá ser aprobada por el Comité de Seguridad de la ' +
        'Información y Respuesta a Incidentes (CSIRI) y el directorio de Avícola Pato Branco.'
      );
    }
  }

  if (infoCode === 'privacypolicy_privacy_policy_updates_title') {
    if (currentLanguage === 'portuguese') {
      return '12 - Atualizações desta Política de Privacidade';
    }
    if (currentLanguage === 'english') {
      return '12 - Updates to this Privacy Policy';
    }
    if (currentLanguage === 'spanish') {
      return '12 - Actualizaciones de esta Política de Privacidad';
    }
  }

  if (infoCode === 'privacypolicy_privacy_policy_updates_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Se modificarmos nossa Política de Privacidade, publicaremos ' +
        'o novo texto no site, com a data de revisão atualizada. Podemos ' +
        'alterar esta Política de Privacidade a qualquer momento. Caso ' +
        'haja alteração significativa nos termos desta Política de ' +
        'Privacidade, podemos informá-lo por meio das informações de ' +
        'contato que tivermos em nosso banco de dados ou por meio de ' +
        'notificação em nosso site.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'If we modify our Privacy Policy, we will post the new text on the ' +
        'website, with the updated revision date. We may change this Privacy ' +
        'Policy at any time. If there is a material change to the terms of ' +
        'this Privacy Policy, we may inform you through the contact information ' +
        'we have in our database or through a notification on our website.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Si modificamos nuestra Política de Privacidad, publicaremos ' +
        'el nuevo texto en el sitio web, con la fecha de revisión ' +
        'actualizada. Podemos cambiar esta Política de Privacidad ' +
        'en cualquier momento. Si hay un cambio sustancial en los ' +
        'términos de esta Política de Privacidad, podemos informarle ' +
        'a través de las informaciones de contacto que tenemos en ' +
        'nuestra base de datos o mediante notificación en nuestro sitio web.'
      );
    }
  }

  if (infoCode === 'privacypolicy_privacy_policy_updates_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Sua utilização do site após as alterações significa que ' +
        'aceitou as Políticas de Privacidade revisadas. Caso, após ' +
        'a leitura da versão revisada, você não esteja de acordo com ' +
        'seus termos, favor encerrar o acesso ao site.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Your use of the site following the changes means that you accept ' +
        'the revised Privacy Policies. If, after reading the revised version, ' +
        'you do not agree with its terms, please terminate access to the site.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Su uso del sitio después de los cambios significa que acepta ' +
        'las Políticas de Privacidad revisadas. Si, después de leer la ' +
        'versión revisada, no está de acuerdo con sus términos, cancele ' +
        'el acceso al sitio web.'
      );
    }
  }

  if (infoCode === 'privacypolicy_personal_data_processing_responsable_title') {
    if (currentLanguage === 'portuguese') {
      return '13 - Encarregado do tratamento dos Dados Pessoais';
    }
    if (currentLanguage === 'english') {
      return '13 - Person in charge of processing Personal Data';
    }
    if (currentLanguage === 'spanish') {
      return '13 - Responsable del Tratamiento de Datos Personales';
    }
  }

  if (
    infoCode === 'privacypolicy_personal_data_processing_responsable_text_01'
  ) {
    if (currentLanguage === 'portuguese') {
      return (
        'Caso pretenda exercer qualquer um dos direitos previstos, ' +
        'inclusive retirar o seu consentimento, nesta Política de ' +
        'Privacidade e/ou nas Leis de Proteção de Dados, ou resolver ' +
        'quaisquer dúvidas relacionadas ao Tratamento de seus Dados ' +
        'Pessoais, favor contatar-nos em: privacidade@avicolapb.com.br ' +
        'ou +55 46 3220-7600 (Luiz Fernando dos Santos).'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'If you wish to exercise any of the rights provided, ' +
        'including withdrawing your consent, in this Privacy ' +
        'Policy and/or in the Data Protection Laws, or to ' +
        'resolve any doubts related to the Processing of ' +
        'your Personal Data, please contact us at: ' +
        'privacidade@avicolapb.com.br or +55 46 3220-7600 ' +
        '(Luiz Fernando dos Santos).'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'En caso tengas la intención de ejercer cualquier de los derechos ' +
        'previstos, incluido el de retirar su consentimiento, en esta ' +
        'Política de Privacidad y/o en las Leyes de Protección de Datos, ' +
        'o resolver cualquier dudas relacionadas al Tratamiento de sus ' +
        'Datos Personales, favor contactarnos en: privacidade@avicolapb.com.br ' +
        'o +55 46 3220-7600 (Luiz Fernando dos Santos).'
      );
    }
  }

  if (infoCode === 'privacypolicy_end_highlighted_text') {
    if (currentLanguage === 'portuguese') {
      return 'Esta política é válida a partir de abril de 2022.';
    }
    if (currentLanguage === 'english') {
      return 'This policy is valid from April 2022.';
    }
    if (currentLanguage === 'spanish') {
      return 'Esta política es válida a partir de abril de 2022.';
    }
  }

  if (infoCode === 'cookies_main_title') {
    if (currentLanguage === 'portuguese') {
      return 'Política de Cookies - Avícola Pato Branco Ltda.';
    }
    if (currentLanguage === 'english') {
      return 'Cookies Policy - Avícola Pato Branco Ltda.';
    }
    if (currentLanguage === 'spanish') {
      return 'Política de Cookies - Avícola Pato Branco Ltda.';
    }
  }

  if (infoCode === 'cookies_main_text_01') {
    if (currentLanguage === 'portuguese') {
      return 'Para a ';
    }
    if (currentLanguage === 'english') {
      return 'For ';
    }
    if (currentLanguage === 'spanish') {
      return 'Para la ';
    }
  }

  if (infoCode === 'cookies_main_text_02_highlighted') {
    if (currentLanguage === 'portuguese') {
      return 'Avícola Pato Branco Ltda';
    }
    if (currentLanguage === 'english') {
      return 'Avícola Pato Branco Ltda';
    }
    if (currentLanguage === 'spanish') {
      return 'Avícola Pato Branco Ltda';
    }
  }

  if (infoCode === 'cookies_main_text_03') {
    if (currentLanguage === 'portuguese') {
      return (
        ', a transparência é fundamental. Por isso, preparamos esta ' +
        'Política para informar de forma clara e objetiva como e em ' +
        'que momentos utilizamos cookies em nossos sites.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        ', transparency is essential. Therefore, we have prepared ' +
        'this Policy to clearly and objectively inform you how and ' +
        'when we use cookies on our websites.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        ', la transparencia es esencial. Por eso, preparamos esta ' +
        'Política para informar de forma clara y objetiva cómo y ' +
        'en qué momentos utilizamos cookies en nuestros sitios web.'
      );
    }
  }

  if (infoCode === 'cookies_what_are_cookies_title') {
    if (currentLanguage === 'portuguese') {
      return 'O que são cookies?';
    }
    if (currentLanguage === 'english') {
      return 'What are cookies?';
    }
    if (currentLanguage === 'spanish') {
      return '¿Qué son cookies?';
    }
  }

  if (infoCode === 'cookies_what_are_cookies_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Quando você visita o site, ele pode armazenar ou recuperar ' +
        'informações em seu navegador, principalmente na forma de ' +
        'cookies, que são arquivos de texto contendo pequenas quantidades ' +
        'de informações. Essas informações podem ser sobre você, suas ' +
        'preferências ou seu dispositivo e são usadas principalmente ' +
        'para que o site funcione como você espera. As informações ' +
        'geralmente não o identificam diretamente, mas podem oferecer ' +
        'uma experiência na internet mais personalizada.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'When you visit the website, it may store or retrieve ' +
        'information in your browser, primarily in the form of ' +
        'cookies, which are text files containing small amounts ' +
        'of information. This information may be about you, your ' +
        'preferences or your device and is primarily used to make ' +
        'the website work as you expect. The information usually ' +
        'does not directly identify you, but it can provide a more ' +
        'personalized web experience.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Cuando visita el sitio web, el puede almacenar o recuperar ' +
        'información en su navegador, principalmente en forma de ' +
        'cookies, que son archivos de texto que contienen pequenas ' +
        'cantidades de informaciones. Estas informaciones pueden ser ' +
        'sobre usted, tus preferencias o su dispositivo y son usadas ' +
        'principalmente para hacer que el sitio web funcione como tu ' +
        'esperas. Las informaciones generalmente no te identifica ' +
        'directamente, pero pueden ofrecer una experiencia de ' +
        'Internet más personalizada.'
      );
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_title') {
    if (currentLanguage === 'portuguese') {
      return 'Quais cookies coletamos?';
    }
    if (currentLanguage === 'english') {
      return 'What cookies do we collect?';
    }
    if (currentLanguage === 'spanish') {
      return '¿Cuales cookies recopilamos?';
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'De acordo com este aviso de cookies, nós e nossos prestadores ' +
        'de serviços terceirizados, mediante seu consentimento, podemos ' +
        'coletar seus Dados Pessoais de diversas formas, incluindo, entre outros:'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'In accordance with this cookie notice, we and our third party ' +
        'service providers, with your consent, we may collect your ' +
        'Personal Data in a variety of ways, including but not limited to:'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'De acuerdo con este aviso de cookies, nosotros y nuestros proveedores ' +
        'de servicios externos, con su consentimiento, podemos recopilar sus ' +
        'Datos Personales de varias maneras. Incluyendo, entre otros:'
      );
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_01_index') {
    if (currentLanguage === 'portuguese') {
      return '1.';
    }
    if (currentLanguage === 'english') {
      return '1.';
    }
    if (currentLanguage === 'spanish') {
      return '1.';
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_01_title') {
    if (currentLanguage === 'portuguese') {
      return 'Por meio do navegador ou do dispositivo: ';
    }
    if (currentLanguage === 'english') {
      return 'Via browser or device: ';
    }
    if (currentLanguage === 'spanish') {
      return 'A través del navegador o del dispositivo: ';
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_01_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'algumas informações são coletadas pela maior parte dos navegadores ' +
        'ou automaticamente por meio de dispositivos de acesso à internet, ' +
        'como o tipo de computador, resolução da tela, nome e versão do ' +
        'sistema operacional, modelo e fabricante do dispositivo, idioma, ' +
        'tipo e versão do navegador de Internet que está utilizando. Podemos ' +
        'utilizar essas informações para assegurar que o site funcione adequadamente.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Some information is collected by most browsers or automatically ' +
        'through internet access devices, such as computer type, screen ' +
        'resolution, operating system name and version, device model and ' +
        'manufacturer, language, browser type and version of Internet you ' +
        'are using. We may use this information to ensure that the site ' +
        'functions properly.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'parte de las informaciones son recopilada por la mayoría de los ' +
        'navegadores o automáticamente a través de dispositivos de acceso ' +
        'en Internet, como el tipo de ordenador, la resolución de pantalla, ' +
        'nombre y versión del sistema operativo, modelo y fabricante del ' +
        'dispositivo, idioma, tipo y versión del navegador de Internet que ' +
        'estás utilizando. Podemos utilizar estas informaciones para asegurar ' +
        'que el sitio web funcione correctamente.'
      );
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_02_index') {
    if (currentLanguage === 'portuguese') {
      return '2.';
    }
    if (currentLanguage === 'english') {
      return '2.';
    }
    if (currentLanguage === 'spanish') {
      return '2.';
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_02_title') {
    if (currentLanguage === 'portuguese') {
      return 'Uso de cookies: ';
    }
    if (currentLanguage === 'english') {
      return 'Use of Cookies: ';
    }
    if (currentLanguage === 'spanish') {
      return 'Uso de cookies: ';
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_02_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'informações sobre o seu uso do site podem ser coletadas por ' +
        'terceiros a partir de cookies. Cookies são informações armazenadas ' +
        'diretamente no computador que você está utilizando. Os cookies permitem ' +
        'a coleta de informações tais como o tipo de navegador, o tempo despendido ' +
        'no site, as páginas visitadas, as preferências de idioma e outros dados de ' +
        'tráfego anônimos. Nós e nossos prestadores de serviços utilizamos informações ' +
        'para proteção e segurança, para facilitar a navegação, exibir informações de ' +
        'modo mais eficiente, e personalizar sua experiência ao utilizar o site. Também ' +
        'coletamos informações estatísticas sobre o uso do site para aprimoramento ' +
        'contínuo do nosso design e funcionalidade, para entender como o site é ' +
        'utilizado e para auxiliá-lo a solucionar questões relativas ao site.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Information about your use of the website may be collected by third ' +
        'parties from cookies. Cookies are information stored directly on the ' +
        'computer you are using. Cookies allow the collection of information ' +
        'such as browser type, time spent on the site, pages visited, language ' +
        'preferences and other anonymous traffic data. We and our service providers ' +
        'use information for protection and security, to make navigation easier, to ' +
        'display information more efficiently, and to personalize your experience when ' +
        'using the site. We also collect statistical information about website usage to ' +
        'continually improve our design and functionality, to understand how the website ' +
        'is used, and to help you resolve issues relating to the website.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Informaciones sobre su uso del sitio web pueden ser recopilada por ' +
        'terceros a partir de los cookies. Cookies son informaciones almacenadas ' +
        'directamente en el ordenador que estás usando. Los cookies permiten la ' +
        'recopilación de informaciones tales como el tipo de navegador, el tiempo ' +
        'pasado en el sitio, las páginas visitadas, las preferencias de idioma y ' +
        'otros datos de tráfico anónimos. Nosotros y nuestros provedores de los ' +
        'servicios utilizamos informaciones para la protección y la seguridad, para ' +
        'facilitar la navegación, muestra informaciones de forma más eficiente y ' +
        'personaliza tu experiencia cuando se utiliza el sitio web. También ' +
        'recopilamos informaciones estadística sobre el uso del sitio web para ' +
        'mejora continua de nuestro diseño y funcionalidad, para comprender cómo ' +
        'se utiliza el sitio web y para ayudarlo a resolver problemas relacionados ' +
        'con el sitio web.'
      );
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_02_text_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Caso não deseje que suas informações sejam coletadas por meio de ' +
        'cookies, há um procedimento simples na maior parte dos navegadores ' +
        'que permite que os cookies sejam automaticamente rejeitados, ou ' +
        'oferece a opção de aceitar ou rejeitar a transferência de um ' +
        'cookie (ou cookies) específico(s) de um site determinado ' +
        'para o seu computador. Entretanto, isso pode gerar ' +
        'inconvenientes no uso do site.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'If you do not wish to have your information collected using cookies, ' +
        'there is a simple procedure in most browsers that allows cookies to be ' +
        'automatically rejected, or gives you the option to accept or reject the ' +
        'transfer of a specific cookie, or cookies from a given website to your ' +
        'computer. However, this may cause inconvenience in the use of the website.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Caso no desea que se recopile su informaciones mediante cookies, ' +
        'existe un procedimiento simple en la mayoría de los navegadores que ' +
        'permite que los cookies se rechacen automáticamente, o le ofrece la ' +
        'opción de aceptar o rechazar la transferencia de un cookie (o cookies) ' +
        'específico.(s) de un sitio web determinado para su ordenador. Sin embargo, ' +
        'esto puede causar inconvenientes en el uso del sitio web.'
      );
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_02_text_03') {
    if (currentLanguage === 'portuguese') {
      return (
        'As definições que escolher podem afetar a sua experiência ' +
        'de navegação e o funcionamento que exige a utilização de ' +
        'cookies. Neste sentido, rejeitamos qualquer responsabilidade ' +
        'pelas consequências resultantes do funcionamento limitado do ' +
        'site provocado pela desativação de cookies no seu dispositivo ' +
        '(incapacidade de definir ou ler um cookie).'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'The settings you choose may affect your browsing experience and ' +
        'the operation that requires the use of cookies. In this regard, we ' +
        'reject any responsibility for the consequences resulting from the ' +
        'limited functioning of the website caused by the deactivation of ' +
        'cookies on your device (inability to set or read a cookie).'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Las configuraciones que elegir pueden afectar su experiencia de ' +
        'navegación y el funcionamiento que requiere el uso de cookies. En ' +
        'este sentido, rechazamos cualquier responsabilidad por las consecuencias ' +
        'derivadas del funcionamiento limitado del sitio web causado por la desactivación ' +
        'de cookies en su dispositivo (imposibilidad de configurar o leer un cookie).'
      );
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_03_index') {
    if (currentLanguage === 'portuguese') {
      return '3.';
    }
    if (currentLanguage === 'english') {
      return '3.';
    }
    if (currentLanguage === 'spanish') {
      return '3.';
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_03_title') {
    if (currentLanguage === 'portuguese') {
      return 'Uso de pixel tags e outras tecnologias similares: ';
    }
    if (currentLanguage === 'english') {
      return 'Use of pixel tags and other similar technologies: ';
    }
    if (currentLanguage === 'spanish') {
      return 'Uso de pixel tags y otras tecnologías similares: ';
    }
  }

  if (infoCode === 'cookies_which_cookies_we_collect_item_03_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'pixel tags (também conhecidos como web beacons e GIFs invisíveis) ' +
        'podem ser utilizados para rastrear ações de usuários do site ' +
        '(incluindo destinatários de e-mails), medir o sucesso das nossas ' +
        'campanhas de marketing e coletar dados estatísticos sobre o uso ' +
        'do site e taxas de resposta, e ainda para outros fins não ' +
        'especificados. Podemos contratar empresas de publicidade ' +
        'comportamental, para obter relatórios sobre os anúncios ' +
        'do site em toda a internet. Para isso, essas empresas ' +
        'utilizam cookies, pixel tags e outras tecnologias para ' +
        'coletar informações sobre a sua utilização, ou sobre a ' +
        'utilização de outros usuários, do nosso site e de sites ' +
        'de terceiros. Nós não somos responsáveis por pixel tags, ' +
        'cookies e outras tecnologias similares utilizadas por terceiros.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Pixel tags (also known as web beacons and invisible GIFs) may be used ' +
        'to track the actions of website users (including email recipients), ' +
        'measure the success of our marketing campaigns, and collect statistical ' +
        'data about website usage and fees. response, and for other unspecified ' +
        'purposes. We may engage behavioral advertising companies to obtain reports ' +
        'on website advertisements across the internet. To this end, these companies ' +
        'use cookies, pixel tags and other technologies to collect information about ' +
        'your use, or the use of other users, of our website and third-party websites. We ' +
        'are not responsible for pixel tags, cookies and other similar technologies used by ' +
        'third parties.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'pixel tags (también conocidos como web beacons y GIFs invisibles) se ' +
        'pueden usar para rastrear acciones de los usuarios del sitio web (incluidos ' +
        'los destinatarios de correo electrónico), medir el éxito de las nuestras ' +
        'campañas de marketing y recopilar datos estadísticos sobre el uso del sitio ' +
        'web y las tarifas de respuesta, y además para otros fines no especificados. Podemos ' +
        'contratar empresas de publicidad conductual para obtener informes sobre anuncios de ' +
        'sitios web en toda la Internet. Con este fin, estas empresas utilizan cookies, pixel ' +
        'tags y otras tecnologías para recopilar informaciones sobre su uso, o sobre el uso de ' +
        'otros usuarios, de nuestro sitio web y de sitios web de terceros. No somos responsables ' +
        'por pixel tags, cookies y otras tecnologías similares utilizadas por terceros.'
      );
    }
  }

  if (infoCode === 'cookies_categories_title') {
    if (currentLanguage === 'portuguese') {
      return 'Categorias de cookies';
    }
    if (currentLanguage === 'english') {
      return 'Cookie Categories';
    }
    if (currentLanguage === 'spanish') {
      return 'Categorías de cookies';
    }
  }

  if (infoCode === 'cookies_categories_text_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Os cookies utilizados no nosso site estão de acordo com os ' +
        'requisitos legais e são enquadrados nas seguintes categorias:'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'The cookies used on our website comply with legal requirements ' +
        'and fall into the following categories:'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Los cookies utilizados en nuestro sitio web cumplen con los ' +
        'requisitos legales y son clasificados en las siguientes categorías:'
      );
    }
  }

  if (infoCode === 'cookies_categories_item_01_index') {
    if (currentLanguage === 'portuguese') {
      return '1.';
    }
    if (currentLanguage === 'english') {
      return '1.';
    }
    if (currentLanguage === 'spanish') {
      return '1.';
    }
  }

  if (infoCode === 'cookies_categories_item_01_title') {
    if (currentLanguage === 'portuguese') {
      return 'Estritamente necessários: ';
    }
    if (currentLanguage === 'english') {
      return 'Strictly required: ';
    }
    if (currentLanguage === 'spanish') {
      return 'Estrictamente necesarias: ';
    }
  }

  if (infoCode === 'cookies_categories_item_01_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'estes cookies permitem que você navegue pelo site e desfrute ' +
        'de recursos essenciais com segurança. Um exemplo são os cookies ' +
        'de segurança, que autenticam os usuários, protegem os seus dados ' +
        'e evitam a criação de logins fraudulentos;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'These cookies allow you to browse the site and enjoy essential ' +
        'features safely. An example is security cookies, which authenticate ' +
        'users, protect their data and prevent the creation of fraudulent logins;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'estes cookies le permiten navegar por el sitio web y disfrutar de ' +
        'funciones esenciales de forma segura. Un ejemplo son los cookies de ' +
        'seguridad, que autentican los usuarios, protegen sus datos y evitan la ' +
        'creación de inícios de sesión fraudulentos;'
      );
    }
  }

  if (infoCode === 'cookies_categories_item_02_index') {
    if (currentLanguage === 'portuguese') {
      return '2.';
    }
    if (currentLanguage === 'english') {
      return '2.';
    }
    if (currentLanguage === 'spanish') {
      return '2.';
    }
  }

  if (infoCode === 'cookies_categories_item_02_title') {
    if (currentLanguage === 'portuguese') {
      return 'Desempenho: ';
    }
    if (currentLanguage === 'english') {
      return 'Performance: ';
    }
    if (currentLanguage === 'spanish') {
      return 'Rendimiento: ';
    }
  }

  if (infoCode === 'cookies_categories_item_02_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'os cookies desta categoria coletam informações de forma codificada ' +
        'e anônima relacionadas ao nosso site virtual, como, por exemplo, o ' +
        'número de visitantes de uma página específica, origem das visitas ao ' +
        'site e quais as páginas acessadas pelo usuário. Todos os dados coletados ' +
        'são utilizados apenas para eventuais melhorias no site e para medir a eficácia ' +
        'da nossa comunicação;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'Cookies in this category collect information in an encrypted and ' +
        'anonymous form related to our virtual website, such as, for example, ' +
        'the number of visitors to a specific page, the origin of visits to the ' +
        'website and which pages the user has accessed. All data collected is used ' +
        'only for eventual improvements to the website and to measure the effectiveness ' +
        'of our communication;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'los cookies de esta categoría recopilan informaciones de forma ' +
        'encriptada y anónima relacionadas con nuestro sitio web virtual, ' +
        'por ejemplo, el número de visitantes de una página específica, ' +
        'origen de las visitas al sitio web y cuales las páginas accedidas ' +
        'por el usuario. Todos los datos recopilados se utilizan solo para ' +
        'eventuales mejoras en el sitio web y para medir la efectividad de ' +
        'nuestra comunicación;'
      );
    }
  }

  if (infoCode === 'cookies_categories_item_03_index') {
    if (currentLanguage === 'portuguese') {
      return '3.';
    }
    if (currentLanguage === 'english') {
      return '3.';
    }
    if (currentLanguage === 'spanish') {
      return '3.';
    }
  }

  if (infoCode === 'cookies_categories_item_03_title') {
    if (currentLanguage === 'portuguese') {
      return 'Funcionalidade: ';
    }
    if (currentLanguage === 'english') {
      return 'Functionality: ';
    }
    if (currentLanguage === 'spanish') {
      return 'Funcionalidad: ';
    }
  }

  if (infoCode === 'cookies_categories_item_03_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'estes cookies são utilizados para lembrar definições de ' +
        'preferências do usuário com o objetivo de melhorar a sua ' +
        'visita no nosso site, como, por exemplo, configurações ' +
        'aplicadas no layout do site ou suas respostas para pop-ups ' +
        'de promoções e cadastros, dessa forma, não será necessário ' +
        'preencher inúmeras vezes;'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'These cookies are used to remember user preference settings ' +
        'in order to improve your visit to our website, such as, for ' +
        'example, settings applied to the website layout or your responses ' +
        'to promotions and registration pop-ups, in this way, it will not be ' +
        'necessary to fill in numerous times;'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'estes cookies se utilizan para recordar configuraciones de ' +
        'preferencias del usuario con el fin de mejorar su visita a ' +
        'nuestro sitio web, como, por ejemplo, configuraciones aplicadas ' +
        'al diseño del sitio web o sus respuestas para pop-ups de promociones ' +
        'y registro, de esa manera, no será necesario llenar innumerables veces;'
      );
    }
  }

  if (infoCode === 'cookies_categories_item_04_index') {
    if (currentLanguage === 'portuguese') {
      return '4.';
    }
    if (currentLanguage === 'english') {
      return '4.';
    }
    if (currentLanguage === 'spanish') {
      return '4.';
    }
  }

  if (infoCode === 'cookies_categories_item_04_title') {
    if (currentLanguage === 'portuguese') {
      return 'Publicidade: ';
    }
    if (currentLanguage === 'english') {
      return 'Advertising: ';
    }
    if (currentLanguage === 'spanish') {
      return 'Publicidad: ';
    }
  }

  if (infoCode === 'cookies_categories_item_04_text') {
    if (currentLanguage === 'portuguese') {
      return (
        'utilizamos cookies com o objetivo de criar campanhas ' +
        'segmentadas e entregar anúncios de acordo com o seu ' +
        'perfil de consumo no nosso site.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'We use cookies in order to create targeted campaigns and ' +
        'deliver ads according to your consumption profile on our website.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'utilizamos cookies com el objetivo de crear campañas ' +
        'dirigidas y ofrecer anuncios de acuerdo con su perfil ' +
        'de consumo en nuestro sitio web.'
      );
    }
  }

  if (infoCode === 'cookies_end_highlighted_text') {
    if (currentLanguage === 'portuguese') {
      return 'Esta política é válida a partir de abril de 2022.';
    }
    if (currentLanguage === 'english') {
      return 'This policy is valid from April 2022.';
    }
    if (currentLanguage === 'spanish') {
      return 'Esta política es válida a partir de abril de 2022.';
    }
  }

  if (infoCode === 'cookies_footer_01') {
    if (currentLanguage === 'portuguese') {
      return 'Nós respeitamos sua privacidade. Utilizamos cookies para melhorar a sua experiência.';
    }
    if (currentLanguage === 'english') {
      return 'We respect your privacy. We use cookies to improve your experience.';
    }
    if (currentLanguage === 'spanish') {
      return 'Respetamos tu privacidad. Usamos cookies para mejorar su experiencia.';
    }
  }

  if (infoCode === 'cookies_footer_02') {
    if (currentLanguage === 'portuguese') {
      return ' Conheça a nossa ';
    }
    if (currentLanguage === 'english') {
      return ' Discover our ';
    }
    if (currentLanguage === 'spanish') {
      return ' Descubra nuestra ';
    }
  }

  if (infoCode === 'cookies_footer_privacy_policy') {
    if (currentLanguage === 'portuguese') {
      return 'política de privacidade';
    }
    if (currentLanguage === 'english') {
      return 'privacy policy';
    }
    if (currentLanguage === 'spanish') {
      return 'política de privacidad';
    }
  }

  if (infoCode === 'cookies_footer_03') {
    if (currentLanguage === 'portuguese') {
      return ' e entenda como funciona clicando ';
    }
    if (currentLanguage === 'english') {
      return ' and understand how it works by clicking ';
    }
    if (currentLanguage === 'spanish') {
      return ' y comprenda cómo funciona haciendo clic ';
    }
  }

  if (infoCode === 'cookies_footer_privacy_dialog') {
    if (currentLanguage === 'portuguese') {
      return 'aqui';
    }
    if (currentLanguage === 'english') {
      return 'here';
    }
    if (currentLanguage === 'spanish') {
      return 'aquí';
    }
  }

  if (infoCode === 'cookies_dialog_menu_item_01') {
    if (currentLanguage === 'portuguese') {
      return 'INFORMAÇÕES';
    }
    if (currentLanguage === 'english') {
      return 'INFORMATION';
    }
    if (currentLanguage === 'spanish') {
      return 'INFORMACIÓN';
    }
  }

  if (infoCode === 'cookies_dialog_menu_item_02') {
    if (currentLanguage === 'portuguese') {
      return 'O QUE SÃO COOKIES?';
    }
    if (currentLanguage === 'english') {
      return 'WHAT ARE COOKIES?';
    }
    if (currentLanguage === 'spanish') {
      return '¿QUÉ SON COOKIES?';
    }
  }

  if (infoCode === 'cookies_dialog_menu_item_03') {
    if (currentLanguage === 'portuguese') {
      return 'COOKIES ESTRITAMENTE NECESSÁRIOS';
    }
    if (currentLanguage === 'english') {
      return 'STRICTLY NECESSARY COOKIES';
    }
    if (currentLanguage === 'spanish') {
      return 'COOKIES ESTRICTAMENTE NECESARIAS';
    }
  }

  if (infoCode === 'cookies_dialog_section_01_title') {
    if (currentLanguage === 'portuguese') {
      return 'Informações';
    }
    if (currentLanguage === 'english') {
      return 'Information';
    }
    if (currentLanguage === 'spanish') {
      return 'Información';
    }
  }

  if (infoCode === 'cookies_dialog_section_01_info_01') {
    if (currentLanguage === 'portuguese') {
      return 'Para a ';
    }
    if (currentLanguage === 'english') {
      return 'For ';
    }
    if (currentLanguage === 'spanish') {
      return 'Para la ';
    }
  }

  if (infoCode === 'cookies_dialog_section_01_info_02') {
    if (currentLanguage === 'portuguese') {
      return 'Avícola Pato Branco Ltda';
    }
    if (currentLanguage === 'english') {
      return 'Avícola Pato Branco Ltda';
    }
    if (currentLanguage === 'spanish') {
      return 'Avícola Pato Branco Ltda';
    }
  }

  if (infoCode === 'cookies_dialog_section_01_info_03') {
    if (currentLanguage === 'portuguese') {
      return (
        ', a transparência é fundamental. Por isso, preparamos ' +
        'esta Política para informar de forma clara e objetiva ' +
        'como e em que momentos utilizamos cookies em nossos sites.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        ', transparency is essential. Therefore, we have prepared ' +
        'this Policy to clearly and objectively inform you how and ' +
        'when we use cookies on our websites.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        ', la transparencia es esencial. Por eso, preparamos esta ' +
        'Política para informar de forma clara y objetiva cómo y en ' +
        'qué momentos utilizamos cookies en nuestros sitios web.'
      );
    }
  }

  if (infoCode === 'cookies_dialog_section_02_title') {
    if (currentLanguage === 'portuguese') {
      return 'O que são cookies?';
    }
    if (currentLanguage === 'english') {
      return 'What are cookies?';
    }
    if (currentLanguage === 'spanish') {
      return '¿Qué son cookies?';
    }
  }

  if (infoCode === 'cookies_dialog_section_02_info') {
    if (currentLanguage === 'portuguese') {
      return (
        'Quando você visita o site, ele pode armazenar ou recuperar ' +
        'informações em seu navegador, principalmente na forma de ' +
        'cookies, que são arquivos de texto contendo pequenas ' +
        'quantidades de informações. Essas informações podem ' +
        'ser sobre você, suas preferências ou seu dispositivo ' +
        'e são usadas principalmente para que o site funcione ' +
        'como você espera. As informações geralmente não o ' +
        'identificam diretamente, mas podem oferecer uma ' +
        'experiência na internet mais personalizada.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'When you visit the website, it may store or retrieve ' +
        'information in your browser, primarily in the form of ' +
        'cookies, which are text files containing small amounts ' +
        'of information. This information may be about you, your ' +
        'preferences or your device and is primarily used to make ' +
        'the website work as you expect. The information usually does ' +
        'not directly identify you, but it can provide a more personalized ' +
        'web experience.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Cuando visita el sitio web, el puede almacenar o recuperar ' +
        'información en su navegador, principalmente en forma de ' +
        'cookies, que son archivos de texto que contienen pequenas ' +
        'cantidades de informaciones. Estas informaciones pueden ser ' +
        'sobre usted, tus preferencias o su dispositivo y son usadas ' +
        'principalmente para hacer que el sitio web funcione como tu ' +
        'esperas. Las informaciones generalmente no te identifica ' +
        'directamente, pero pueden ofrecer una experiencia de ' +
        'Internet más personalizada.'
      );
    }
  }

  if (infoCode === 'cookies_dialog_section_03_title') {
    if (currentLanguage === 'portuguese') {
      return 'Cookies Estritamente Necessários';
    }
    if (currentLanguage === 'english') {
      return 'Strictly Necessary Cookies';
    }
    if (currentLanguage === 'spanish') {
      return 'Cookies Estrictamente Necesarias';
    }
  }

  if (infoCode === 'cookies_dialog_section_03_info_01') {
    if (currentLanguage === 'portuguese') {
      return (
        'Para que possamos salvar suas preferências de configuração de ' +
        'cookies, o Cookie Estritamente Necessário deve estar sempre ' +
        'ativado.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'In order for us to save your cookie setting preferences, the ' +
        'Strictly Necessary Cookie must always be enabled.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'Para que podamos guardar sus preferencias de configuración de ' +
        'cookies, la cookie estrictamente necesaria siempre debe estar habilitada.'
      );
    }
  }

  if (infoCode === 'cookies_dialog_section_03_info_02') {
    if (currentLanguage === 'portuguese') {
      return (
        'Em casos de desabilitar este cookie, não será possível salvar ' +
        'suas preferências. Significando assim, que toda vez que você ' +
        'visitar este site, será necessário habilitar ou desabilitar os ' +
        'cookies novamente.'
      );
    }
    if (currentLanguage === 'english') {
      return (
        'In cases of disabling this cookie, it will not be possible to ' +
        'save your preferences. This means that every time you visit this ' +
        'website, you will need to enable or disable cookies again.'
      );
    }
    if (currentLanguage === 'spanish') {
      return (
        'En caso de deshabilitar esta cookie, no será posible guardar ' +
        'sus preferencias. Esto significa que cada vez que visite este ' +
        'sitio web, deberá habilitar o deshabilitar las cookies nuevamente.'
      );
    }
  }

  if (infoCode === 'cookies_dialog_cookies_policy_link') {
    if (currentLanguage === 'portuguese') {
      return 'Clique aqui para acessar a Política de Cookies completa';
    }
    if (currentLanguage === 'english') {
      return 'Click here to access the complete Cookies Policy';
    }
    if (currentLanguage === 'spanish') {
      return 'Clic aquí para acceder la Política de cookies completa';
    }
  }

  if (infoCode === 'cookies_dialog_button_enable_all') {
    if (currentLanguage === 'portuguese') {
      return 'Habilitar Todos';
    }
    if (currentLanguage === 'english') {
      return 'Enable All';
    }
    if (currentLanguage === 'spanish') {
      return 'Habilitar';
    }
  }

  if (infoCode === 'cookies_dialog_button_save') {
    if (currentLanguage === 'portuguese') {
      return 'Salvar';
    }
    if (currentLanguage === 'english') {
      return 'Save';
    }
    if (currentLanguage === 'spanish') {
      return 'Guardar';
    }
  }

  if (infoCode === 'toggle_enable') {
    if (currentLanguage === 'portuguese') {
      return 'Habilitar';
    }
    if (currentLanguage === 'english') {
      return 'Enable';
    }
    if (currentLanguage === 'spanish') {
      return 'Habilitar';
    }
  }

  if (infoCode === 'toggle_disable') {
    if (currentLanguage === 'portuguese') {
      return 'Desabilitar';
    }
    if (currentLanguage === 'english') {
      return 'Disable';
    }
    if (currentLanguage === 'spanish') {
      return 'Desactivar';
    }
  }

  return '';
}
