import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import PMInternalProjectsGallerySmallModal from '~/components/PMInternalProjectsGallerySmallModal';
import PMInternalProjectsGalleryLargeModal from '~/components/PMInternalProjectsGalleryLargeModal';
import PMInternalProjectsVideoModal from '~/components/PMInternalProjectsVideoModal';
import PMInternalProjectsYouTubeModal from '~/components/PMInternalProjectsYouTubeModal';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  Container,
  ContentWrapper,
  FlipContainer,
  Flipper,
  Front,
  Back,
  ContentItemBorder,
  ContentItemImageWrapper,
  ContentItemImage,
  ContentItemText,
} from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1225 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1225, min: 740 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 740, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

interface IPhoto {
  src: string;
}

const PMInternalProjectsMultiCarousel = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();
  const [isOpenGallerySmallModal, setIsOpenGallerySmallModal] = useState(false);
  const [titleGallerySmallModal, setTitleGallerySmallModal] = useState('');
  const [text01GallerySmallModal, setText01GallerySmallModal] = useState('');
  const [text02GallerySmallModal, setText02GallerySmallModal] = useState('');
  const [photosGallerySmallModal, setPhotosGallerySmallModal] =
    useState<IPhoto[]>();
  const [isOpenGalleryLargeModal, setIsOpenGalleryLargeModal] = useState(false);
  const [titleGalleryLargeModal, setTitleGalleryLargeModal] = useState('');
  const [textGalleryLargeModal, setTextGalleryLargeModal] = useState('');
  const [photosGalleryLargeModal, setPhotosGalleryLargeModal] =
    useState<IPhoto[]>();
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const [titleVideoModal, setTitleVideoModal] = useState('');
  const [textVideoModal, setTextVideoModal] = useState('');
  const [srcVideoModal, setSrcVideoModal] = useState('');
  const [isOpenYouTubeModal, setIsOpenYouTubeModal] = useState(false);
  const [titleYouTubeModal, setTitleYouTubeModal] = useState('');
  const [textYouTubeModal, setTextYouTubeModal] = useState('');

  function clearGallerySmallModal(): void {
    setTitleGallerySmallModal('');
    setText01GallerySmallModal('');
    setText02GallerySmallModal('');
  }

  function clearGalleryLargeModal(): void {
    setTitleGalleryLargeModal('');
    setTextGalleryLargeModal('');
  }

  function clearVideoModal(): void {
    setTitleVideoModal('');
    setTextVideoModal('');
  }

  function clearYouTubeModal(): void {
    setTitleYouTubeModal('');
    setTextYouTubeModal('');
  }

  function getTitleModal(type: string): string {
    let title_modal: string | undefined;

    if (type === 'festa_01') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_party_01_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Festa Junina';
    }

    if (type === 'trabalho_em_altura') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_work_at_height_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Trabalho em Altura';
    }

    if (type === 'primeiro_encontro_tecnico') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_first_technical_meeting_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return '1º Encontro Técnico';
    }

    if (type === 'desenvolvimento_lideres') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_leader_development_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Desenvolvimento de Líderes';
    }

    if (type === 'brigada_incendio') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_fire_brigade_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Brigada de Incêndio';
    }

    if (type === 'dia_das_criancas') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_childrens_day_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Dia das Crianças';
    }

    if (type === '50_anos') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_50_years_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return '50 Anos da Avícola Pato Branco';
    }

    if (type === 'colaboradores') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_employees_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Gratidão por fazer Parte!';
    }

    if (type === 'outubro_rosa') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_pink_october_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Outubro Rosa';
    }

    if (type === 'natal') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_christmas_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Entrega Cestas de Natal';
    }

    if (type === 'novembro_azul') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_blue_november_modal_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Novembro Azul';
    }

    return '';
  }

  function getText(type: string): string {
    let text_modal: string | undefined;

    if (type === 'festa_01') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_party_01_modal_text',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Algumas imagens de nossa Festa Junina 2022, ocorrida em nossa ' +
        'sede administrativa no dia 15 de junho.'
      );
    }

    if (type === 'trabalho_em_altura_01') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_work_at_height_modal_text_01',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'O setor de Segurança no Trabalho da Avícola Pato Branco, ' +
        'juntamente com o SENAR (Serviço Nacional de Aprendizagem Rural) ' +
        'proporcionou recentemente um treinamento de NR (Norma Regulamentadora) ' +
        '35 - Trabalho em Altura, para os colaboradores que desenvolvem atividades ' +
        'de manutenção.'
      );
    }

    if (type === 'trabalho_em_altura_02') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_work_at_height_modal_text_02',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'O intuito do treinamento foi de qualificar e preparar os colaboradores ' +
        'para situações que exijam mão de obra especializada, abrangendo desde o ' +
        'planejamento, organização até a execução das atividades, a fim de garantir ' +
        'a segurança do colaborador e de terceiros, evitando a ocorrência de ' +
        'acidentes. Parabenizamos a todos pelo desempenho e dedicação.'
      );
    }

    if (type === 'primeiro_encontro_tecnico') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_first_technical_meeting_modal_text',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Encontro ocorrido nos dias 12 e 13 de maio para os nossos ' +
        'monitores. O evento contou com palestrantes das seguintes ' +
        'empresas parceiras: AES Saúde Animal, Cobb América do Sul, ' +
        'Elanco Brasil, MSD Saúde Animal e Royal Pas Reform.'
      );
    }

    if (type === 'desenvolvimento_lideres_01') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_leader_development_modal_text_01',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Recentemente iniciamos com a empresa Realize Gestão de Pessoas, ' +
        'treinamento e mentoria para os líderes daqui da Avícola, com um ' +
        'olhar principalmente voltado para as pessoas. Este é um momento ' +
        'de novas perspectivas e engajar os colaboradores com a cultura ' +
        'da empresa não é uma tarefa simples.'
      );
    }

    if (type === 'desenvolvimento_lideres_02') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_leader_development_modal_text_02',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'A mentoria vem para desenvolver os nossos líderes, para ' +
        'que esses atendam as expectativas da empresa, superem ' +
        'desafios e estejam aptos a lidar com situações inesperadas ' +
        'do dia a dia. Para tanto, necessitam que seus conhecimentos ' +
        'técnicos e comportamentais estejam em constante processo de evolução.'
      );
    }

    if (type === 'brigada_incendio_01') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_fire_brigade_modal_text_01',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Tendo em vista que a brigada de incêndio tem como intuito ' +
        'preservar a vida e os bens de uma instituição, é ela que ' +
        'age diante de princípios de incêndio e na prestação de ' +
        'socorro em situações incomuns. No mês de março de 2022, ' +
        'foi realizado treinamento com novos brigadistas, ' +
        'juntamente com o SENAR (Serviço Nacional de Aprendizagem Rural).'
      );
    }

    if (type === 'brigada_incendio_02') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_fire_brigade_modal_text_02',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Nele, nossos brigadistas aprenderam tanto a parte teórica quanto ' +
        'a parte prática do atendimento de primeiros socorros e do combate ' +
        'a incêndio, sendo realizadas atividades de controle e extinção de ' +
        'focos de incêndio utilizando os extintores e hidrantes, também sendo ' +
        'feitas simulações de atendimentos em caso de acidentes. Agradecemos a ' +
        'todos pelo esforço e dedicação ao treinamento e parabenizamos os novos brigadistas.'
      );
    }

    if (type === 'dia_das_criancas_01') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_childrens_day_modal_text_01',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'No mês de outubro de 2021, em comemoração ao Dia das Crianças, ' +
        'foi realizada a entrega de brindes para os filhos dos nossos ' +
        'colaboradores menores de 12 anos.'
      );
    }

    if (type === 'dia_das_criancas_02') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_childrens_day_modal_text_02',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Foi muito gratificante ver a felicidade de cada criança e o ' +
        'carinho dos pais conosco: "Obrigado por lembrar dos nossos pequenos ' +
        'nesta data. São esses pequenos detalhes que fazem, cada dia mais, ter ' +
        'vontade de vestir esta camisa", ressalta um de nossos colaboradores. Isso ' +
        'faz valer a pena todo esforço e empenho nosso.'
      );
    }

    if (type === '50_anos') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_50_years_modal_text',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Em nosso aniversário de 50 anos, nós da Avícola Pato Branco ' +
        'convidamos você a conhecer um pouco mais da nossa história.'
      );
    }

    if (type === 'colaboradores') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_employees_modal_text',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'O maior patrimônio que a Avícola Pato Branco possui são seus ' +
        'colaboradores. É comprometimento, dedicação, honestidade e ' +
        'seriedade. Nas imagens, apresentamos os colaboradores recebendo ' +
        'uma homenagem, lembrando o período que eles estão vestindo a ' +
        'camisa da Avícola, trabalhando em equipe e remando na mesma direção.'
      );
    }

    if (type === 'outubro_rosa') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_pink_october_modal_text',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Hoje contamos com a participação da Vânia, um exemplo de ' +
        'resiliência, onde ela fala sobre sua história de superação ' +
        'com o câncer e suas lutas diárias. O quanto importante é o ' +
        'tratamento, a realização de exames preventivos e apoio do ' +
        'Grupo de Apoio a Mama (Gama), onde dá um suporte completo ao ' +
        'paciente e familiares que estão enfrentando a luta contra o ' +
        'câncer e outras doenças.'
      );
    }

    if (type === 'natal') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_christmas_modal_text',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'O clima do Natal tomou conta dos ares da Avícola! Mesmo em ' +
        'um ano com tantos acontecimentos atípicos que atingiram o ' +
        'mundo, não poderia ser deixado de presentear quem está sempre ' +
        'ao nosso lado, cumprindo suas tarefas com dedicação, empenho e ' +
        'multiplicando sorrisos com a gente. Por isso, a Avícola entregou ' +
        'um presente de Natal, como um singelo agradecimento pela colaboração ' +
        'durante o ano de 2020.'
      );
    }

    if (type === 'novembro_azul_01') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_blue_november_modal_text_01',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Em alusão ao Novembro Azul, a Avícola Pato Branco juntamente ' +
        'com o Dr. Gabriel, a enfermeira Analine e agente de saúde ' +
        'Verediana, da UBS Morumbi, promoveu um momento de informação ' +
        'sobre o câncer de próstata, ressaltando a importância do ' +
        'autocuidado, a realização dos exames periódicos, além de ' +
        'apresentar dados e esclarecer dúvidas dos participantes ' +
        'sobre o câncer que mais atinge os homens.'
      );
    }

    if (type === 'novembro_azul_02') {
      text_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_internal_projects_blue_november_modal_text_02',
      );
      if (text_modal) {
        return text_modal;
      }

      return (
        'Agradecemos a UBS Morumbi e a todos que participaram ' +
        'deste momento. Faça parte dessa luta, previna-se!'
      );
    }

    return '';
  }

  function getPhotos(type: string): IPhoto[] {
    if (type === 'festa_01') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_01.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_02.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_03.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_04.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_05.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_06.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_07.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_08.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_09.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/festa_junina/festa_junina_10.jpg',
        },
      ];
    }

    if (type === 'trabalho_em_altura') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/treinamento_01.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/treinamento_02.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/treinamento_03.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/treinamento_04.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/treinamento_05.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/treinamento_06.jpg',
        },
      ];
    }

    if (type === 'primeiro_encontro_tecnico') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/encontro_01.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/encontro_02.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/encontro_03.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/encontro_04.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/encontro_05.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/encontro_06.jpeg',
        },
      ];
    }

    if (type === 'desenvolvimento_lideres') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_01.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_02.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_03.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_04.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_05.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_06.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_07.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_08.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_09.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_10.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_11.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_12.jpeg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/lideres_13.jpeg',
        },
      ];
    }

    if (type === 'brigada_incendio') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_01.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_02.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_03.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_04.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_05.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_06.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_07.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_08.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/incendio_09.jpg',
        },
      ];
    }

    if (type === 'dia_das_criancas') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_01.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_02.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_03.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_04.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_05.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_06.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_07.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_08.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_09.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_10.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_11.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/dia_das_criancas/crianca_12.png',
        },
      ];
    }

    if (type === 'colaboradores') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_01.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_02.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_03.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_04.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_05.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_06.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_07.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_08.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_09.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_10.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_11.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_12.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_13.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_14.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_15.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_16.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_17.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_18.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_19.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_20.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_21.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_22.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/colaboradores/colaboradores_23.jpg',
        },
      ];
    }

    if (type === 'natal') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_01.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_02.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_03.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_04.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_05.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_06.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_07.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_08.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_09.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_10.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_11.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_12.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_13.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_14.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_15.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_16.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_17.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_18.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_19.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_20.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_21.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_22.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_23.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_24.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_25.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_26.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_27.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_28.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_29.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_30.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_31.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_32.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_33.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_34.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_35.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_36.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_37.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_38.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_39.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_40.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_41.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_42.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_43.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_44.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_45.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_46.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_47.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_48.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_49.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_50.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_51.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_52.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_53.jpg',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/natal/cesta_natal_54.jpg',
        },
      ];
    }

    if (type === 'novembro_azul') {
      return [
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_01.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_02.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_03.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_04.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_05.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_06.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_07.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_08.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_09.png',
        },
        {
          src: 'images/gestao_de_pessoas/projetos_internos/novembro_azul/novembro_azul_10.png',
        },
      ];
    }

    return [];
  }

  function getCover(type: string): React.ReactNode {
    if (type === 'festa_01') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/festa_junina/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/festa_junina/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/festa_junina/capa_pt.png" />
      );
    }

    if (type === 'trabalho_em_altura') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/trabalho_em_altura/capa_pt.png" />
      );
    }

    if (type === 'primeiro_encontro_tecnico') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/primeiro_encontro_tecnico/capa_pt.png" />
      );
    }

    if (type === 'desenvolvimento_lideres') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/desenvolvimento_de_lideres/capa_pt.png" />
      );
    }

    if (type === 'brigada_incendio') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/brigada_de_incendio/capa_pt.png" />
      );
    }

    if (type === 'dia_das_criancas') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/dia_das_criancas/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/dia_das_criancas/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/dia_das_criancas/capa_pt.png" />
      );
    }

    if (type === '50_anos') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/50_anos/capa.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/50_anos/capa.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/50_anos/capa.png" />
      );
    }

    if (type === 'colaboradores') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/colaboradores/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/colaboradores/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/colaboradores/capa_pt.png" />
      );
    }

    if (type === 'outubro_rosa') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/outubro_rosa/capa.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/outubro_rosa/capa.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/outubro_rosa/capa.png" />
      );
    }

    if (type === 'natal') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/natal/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/natal/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/natal/capa_pt.png" />
      );
    }

    if (type === 'novembro_azul') {
      if (currentLanguage === 'english') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/novembro_azul/capa_en.png" />
        );
      }

      if (currentLanguage === 'spanish') {
        return (
          <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/novembro_azul/capa_es.png" />
        );
      }

      return (
        <ContentItemImage src="images/gestao_de_pessoas/projetos_internos/novembro_azul/capa_pt.png" />
      );
    }

    return <></>;
  }

  return (
    <>
      <Container>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          customTransition="all .5"
          containerClass="carousel-container"
          removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGallerySmallModal(true);
                setTitleGallerySmallModal(getTitleModal('festa_01'));
                setText01GallerySmallModal(getText('festa_01'));
                setPhotosGallerySmallModal(getPhotos('festa_01'));
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('festa_01')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_party_01_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGallerySmallModal(true);
                setTitleGallerySmallModal(getTitleModal('trabalho_em_altura'));
                setText01GallerySmallModal(getText('trabalho_em_altura_01'));
                setText02GallerySmallModal(getText('trabalho_em_altura_02'));
                setPhotosGallerySmallModal(getPhotos('trabalho_em_altura'));
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('trabalho_em_altura')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_work_at_height_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGallerySmallModal(true);
                setTitleGallerySmallModal(
                  getTitleModal('primeiro_encontro_tecnico'),
                );
                setText01GallerySmallModal(
                  getText('primeiro_encontro_tecnico'),
                );
                setPhotosGallerySmallModal(
                  getPhotos('primeiro_encontro_tecnico'),
                );
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('primeiro_encontro_tecnico')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_first_technical_meeting_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGallerySmallModal(true);
                setTitleGallerySmallModal(
                  getTitleModal('desenvolvimento_lideres'),
                );
                setText01GallerySmallModal(
                  getText('desenvolvimento_lideres_01'),
                );
                setText02GallerySmallModal(
                  getText('desenvolvimento_lideres_02'),
                );
                setPhotosGallerySmallModal(
                  getPhotos('desenvolvimento_lideres'),
                );
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('desenvolvimento_lideres')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_leader_development_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGallerySmallModal(true);
                setTitleGallerySmallModal(getTitleModal('brigada_incendio'));
                setText01GallerySmallModal(getText('brigada_incendio_01'));
                setText02GallerySmallModal(getText('brigada_incendio_02'));
                setPhotosGallerySmallModal(getPhotos('brigada_incendio'));
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('brigada_incendio')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_fire_brigade_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGallerySmallModal(true);
                setTitleGallerySmallModal(getTitleModal('dia_das_criancas'));
                setText01GallerySmallModal(getText('dia_das_criancas_01'));
                setText02GallerySmallModal(getText('dia_das_criancas_02'));
                setPhotosGallerySmallModal(getPhotos('dia_das_criancas'));
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('dia_das_criancas')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_childrens_day_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenYouTubeModal(true);
                setTitleYouTubeModal(getTitleModal('50_anos'));
                setTextYouTubeModal(getText('50_anos'));
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('50_anos')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_50_years_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGallerySmallModal(true);
                setTitleGallerySmallModal(getTitleModal('colaboradores'));
                setText01GallerySmallModal(getText('colaboradores'));
                setPhotosGallerySmallModal(getPhotos('colaboradores'));
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('colaboradores')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_employees_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenVideoModal(true);
                setTitleVideoModal(getTitleModal('outubro_rosa'));
                setTextVideoModal(getText('outubro_rosa'));
                setSrcVideoModal(
                  'videos/gestao_de_pessoas/projetos_internos/outubro_rosa/depoimento.mp4',
                );
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('outubro_rosa')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_pink_october_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGalleryLargeModal(true);
                setTitleGalleryLargeModal(getTitleModal('natal'));
                setTextGalleryLargeModal(getText('natal'));
                setPhotosGalleryLargeModal(getPhotos('natal'));
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('natal')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_christmas_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenGallerySmallModal(true);
                setTitleGallerySmallModal(getTitleModal('novembro_azul'));
                setText01GallerySmallModal(getText('novembro_azul_01'));
                setText02GallerySmallModal(getText('novembro_azul_02'));
                setPhotosGallerySmallModal(getPhotos('novembro_azul'));
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    {getCover('novembro_azul')}
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/projetos_internos/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_internal_projects_blue_november_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>
        </Carousel>

        <PMInternalProjectsGallerySmallModal
          isOpen={isOpenGallerySmallModal}
          title={titleGallerySmallModal}
          text_01={text01GallerySmallModal}
          text_02={text02GallerySmallModal}
          photos={photosGallerySmallModal}
          setIsOpen={setIsOpenGallerySmallModal}
          onClose={() => {
            clearGallerySmallModal();
          }}
        />

        <PMInternalProjectsGalleryLargeModal
          isOpen={isOpenGalleryLargeModal}
          title={titleGalleryLargeModal}
          text={textGalleryLargeModal}
          photos={photosGalleryLargeModal}
          setIsOpen={setIsOpenGalleryLargeModal}
          onClose={() => {
            clearGalleryLargeModal();
          }}
        />

        <PMInternalProjectsVideoModal
          isOpen={isOpenVideoModal}
          title={titleVideoModal}
          text={textVideoModal}
          src={srcVideoModal}
          setIsOpen={setIsOpenVideoModal}
          onClose={() => {
            clearVideoModal();
          }}
        />

        <PMInternalProjectsYouTubeModal
          isOpen={isOpenYouTubeModal}
          title={titleYouTubeModal}
          text={textYouTubeModal}
          setIsOpen={setIsOpenYouTubeModal}
          onClose={() => {
            clearYouTubeModal();
          }}
        />
      </Container>
    </>
  );
};

export default PMInternalProjectsMultiCarousel;
