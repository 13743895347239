import React, { useState } from 'react';
import {
  FaWhatsapp,
  FaLinkedin,
  FaInstagram,
  FaFacebookSquare,
  FaYoutube,
} from 'react-icons/fa';

import PrivacyDialog from '../PrivacyDialog';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  MainContainer,
  FirstContainer,
  SecondContainer,
  FirstWrapper,
  SecondWrapper,
  ThirdWrapper,
  LogoWrapper,
  LogoImage,
  PhoneWrapper,
  PhoneLink,
  MenuWrapper,
  MenuTitle,
  MenuItems,
  MenuItem,
  MenuItemNewTab,
  MenuItemDialog,
  SocialMediaWrapper,
  SocialMediaTitle,
  SocialMediaContent,
  SocialMediaLink,
  SocialMediaIcon,
  CustomerPortalWrapper,
  CustomerPortalLink,
  ReportingChannelWrapper,
  ReportingChannelLink,
  LocalizationWrapper,
  LocalizationTitle,
  LocalizationContent,
  DevelopmentLink,
} from './styles';

const Footer = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();
  const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);

  return (
    <MainContainer>
      <FirstContainer>
        <FirstWrapper>
          <LogoWrapper>
            <LogoImage src="images/rodape/logotipo.png" />
          </LogoWrapper>

          <PhoneWrapper>
            <FaWhatsapp size={30} />
            <PhoneLink
              href="https://api.whatsapp.com/send/?phone=554632207600&text&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              (46) 3220-7600
            </PhoneLink>
          </PhoneWrapper>
        </FirstWrapper>

        <SecondWrapper>
          <MenuWrapper>
            <MenuTitle>
              {getLanguageInfo(currentLanguage, 'footer_menu_title')}
            </MenuTitle>
            <MenuItems>
              <MenuItem to="home" smooth offset={-90}>
                {getLanguageInfo(currentLanguage, 'nav_home')}
              </MenuItem>

              <MenuItem to="aboutus" smooth offset={-90}>
                {getLanguageInfo(currentLanguage, 'nav_about_us')}
              </MenuItem>

              <MenuItem to="ourstory" smooth offset={-90}>
                {getLanguageInfo(currentLanguage, 'nav_our_story')}
              </MenuItem>

              <MenuItem to="production" smooth offset={-90}>
                {getLanguageInfo(currentLanguage, 'nav_production')}
              </MenuItem>

              <MenuItem to="certifications" smooth offset={-90}>
                {getLanguageInfo(currentLanguage, 'nav_certifications')}
              </MenuItem>

              <MenuItem to="peoplemanagement" smooth offset={300}>
                {getLanguageInfo(currentLanguage, 'nav_people_management')}
              </MenuItem>

              <MenuItem to="contact" smooth offset={-90}>
                {getLanguageInfo(currentLanguage, 'nav_contact')}
              </MenuItem>

              <MenuItemNewTab
                to={{
                  pathname: `/politicadeprivacidade`,
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getLanguageInfo(currentLanguage, 'nav_privacy_policy')}
              </MenuItemNewTab>

              <MenuItemDialog onClick={() => setIsOpenPrivacyDialog(true)}>
                {getLanguageInfo(currentLanguage, 'nav_cookies_policy')}
              </MenuItemDialog>

              <MenuItemNewTab
                to={{
                  pathname: `https://protheus.avicolapb.com.br:84/portal/u_pavigp01.apw`,
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getLanguageInfo(currentLanguage, 'nav_restricted_access')}
              </MenuItemNewTab>
            </MenuItems>
          </MenuWrapper>
        </SecondWrapper>

        <ThirdWrapper>
          <SocialMediaWrapper>
            <SocialMediaTitle>
              {getLanguageInfo(currentLanguage, 'footer_social_media_title')}
            </SocialMediaTitle>

            <SocialMediaContent>
              <SocialMediaLink
                href="https://www.linkedin.com/in/avicolapb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialMediaIcon>
                  <FaLinkedin size={30} />
                </SocialMediaIcon>
              </SocialMediaLink>

              <SocialMediaLink
                href="https://www.instagram.com/avicolapb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialMediaIcon>
                  <FaInstagram size={30} />
                </SocialMediaIcon>
              </SocialMediaLink>

              <SocialMediaLink
                href="https://facebook.com.br/avicolapb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialMediaIcon>
                  <FaFacebookSquare size={30} />
                </SocialMediaIcon>
              </SocialMediaLink>

              <SocialMediaLink
                href="https://www.youtube.com/channel/UCNuZlJqKV1LrVEd3nZGc2zw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialMediaIcon>
                  <FaYoutube size={30} />
                </SocialMediaIcon>
              </SocialMediaLink>
            </SocialMediaContent>
          </SocialMediaWrapper>

          <CustomerPortalWrapper>
            <CustomerPortalLink
              to={{
                pathname:
                  'https://protheus.avicolapb.com.br:84/portal/u_paviclie.apw',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getLanguageInfo(currentLanguage, 'footer_customer_portal_title')}
            </CustomerPortalLink>
          </CustomerPortalWrapper>

          <ReportingChannelWrapper>
            <ReportingChannelLink
              to={{
                pathname: 'https://canal.ouvidordigital.com.br/avicolapb',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getLanguageInfo(
                currentLanguage,
                'footer_reporting_channel_title',
              )}
            </ReportingChannelLink>
          </ReportingChannelWrapper>

          <LocalizationWrapper>
            <LocalizationTitle>
              {getLanguageInfo(currentLanguage, 'footer_localization_title')}
            </LocalizationTitle>

            <LocalizationContent>
              {getLanguageInfo(currentLanguage, 'footer_localization_info_01')}
              <br />
              {getLanguageInfo(currentLanguage, 'footer_localization_info_02')}
            </LocalizationContent>
          </LocalizationWrapper>
        </ThirdWrapper>
      </FirstContainer>

      <SecondContainer>
        {getLanguageInfo(currentLanguage, 'footer_development_01')}
        <DevelopmentLink
          href="https://www.instagram.com/hopmkt/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hop Marketing
        </DevelopmentLink>
        {getLanguageInfo(currentLanguage, 'footer_development_02')}
        <DevelopmentLink
          href="https://www.newcomm.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          NewComm Sistemas
        </DevelopmentLink>
        .
      </SecondContainer>

      <PrivacyDialog
        isOpen={isOpenPrivacyDialog}
        setIsOpen={setIsOpenPrivacyDialog}
      />
    </MainContainer>
  );
};

export default Footer;
