import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import { shade } from 'polished';
import Button from '~/components/Button';

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    margin-top: 10%;
    margin-left: 50%;
    transform: translate(-50%, -10%);
    background-color: ${theme.colors.veryDarkBlue};
    box-shadow: ${theme.shadows.default};
    border-radius: ${theme.radius.meddium};
    width: 80%;
    max-width: 700px;

    @media (max-width: 890px) {
      margin-top: 15%;
    }

    @media (max-width: 660px) {
      margin-top: 25%;
    }

    @media (max-width: 450px) {
      margin-top: 20rem;
    }
  `}
`;

export const Container = styled.div`
  padding: 1rem 1rem 1rem 1rem;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    padding: 2rem 5rem 0 5rem;
    color: ${theme.colors.strongRed};
    font-size: ${theme.fontSizes.xxxxLarge};
    text-align: center;
  `}
`;

export const Text = styled.div`
  ${({ theme }) => css`
    padding: 3rem 5rem 0 5rem;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.default};
    text-align: justify;
    text-indent: 5rem;
  `}
`;

export const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 2rem;
  padding: 2rem 2rem 2rem 2rem;
  max-width: 680px;
`;

export const StyledYouTube = styled(YouTube)`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const CloseButton = styled(Button)`
  ${({ theme }) => css`
    float: right;
    margin-right: -3.5rem;
    padding: 0;
    width: 5rem;
    height: 5rem;
    margin-top: -3.5rem;
    border-radius: 50%;
    background: ${theme.colors.white};
    color: ${theme.colors.veryDarkBlue};
    :hover {
      transform: scale(1.1, 1.1);
      background: ${shade(0.2, theme.colors.white)};
    }
  `}
`;
