import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.veryDarkBlue};
  `}
`;

export const FirstContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 890px) {
    flex-direction: column;
  }
`;

export const SecondContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
    color: ${theme.colors.strongRed};
    font-size: ${theme.fontSizes.small};
  `}
`;

export const FirstWrapper = styled.div`
  width: 30%;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;

  @media (max-width: 890px) {
    width: 100%;
    padding-top: 5rem;
  }
`;

export const SecondWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  padding-top: 2rem;

  @media (max-width: 890px) {
    width: 100%;
    padding-top: 5rem;
    padding-left: 2rem;
    align-items: start;
  }
`;

export const ThirdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-top: 2rem;
  padding-left: 10rem;

  @media (max-width: 890px) {
    width: 100%;
    padding-top: 5rem;
    padding-left: 2rem;
    align-items: start;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
`;

export const LogoImage = styled.img`
  width: 25rem;
`;

export const PhoneWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
  `}
`;

export const PhoneLink = styled.a`
  ${({ theme }) => css`
    margin-left: 0.5rem;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.large};

    &:hover {
      transform: scale(1.1, 1.1);
    }
  `}
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media (max-width: 890px) {
    align-items: start;
  }
`;

export const MenuTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxLarge};
    margin-left: -9rem;

    @media (max-width: 890px) {
      margin-left: 0;
    }
  `}
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.large};
    font-weight: 400;
    text-align: left;
    line-height: 1.6;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  `}
`;

export const MenuItemNewTab = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.large};
    font-weight: 400;
    text-align: left;
    line-height: 1.6;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  `}
`;

export const MenuItemDialog = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.large};
    font-weight: 400;
    text-align: left;
    line-height: 1.6;

    &:hover {
      transform: scale(1.1, 1.1);
      cursor: pointer;
    }
  `}
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialMediaTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxLarge};
  `}
`;

export const SocialMediaContent = styled.div`
  display: flex;
`;

export const SocialMediaLink = styled.a`
  ${({ theme }) => css`
    margin-right: 1rem;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};

    &:hover {
      transform: scale(1.1, 1.1);
    }
  `}
`;

export const SocialMediaIcon = styled.div`
  &:hover {
    transform: scale(1.1, 1.1);
  }
`;

export const ReportingChannelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  @media (max-width: 890px) {
    margin-top: 5rem;
  }
`;

export const ReportingChannelLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxLarge};

    &:hover {
      transform: scale(1, 1.1);
    }
  `}
`;

export const LocalizationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  @media (max-width: 890px) {
    margin-top: 5rem;
  }
`;

export const LocalizationTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxLarge};
  `}
`;

export const LocalizationContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.meddium};
  `}
`;

export const DevelopmentLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
    text-decoration: underline;
  `}
`;
