import React, { Dispatch, SetStateAction } from 'react';
import ResponsiveGallery from 'react-responsive-gallery';
import { FaTimes } from 'react-icons/fa';

import {
  StyledModal,
  Container,
  Title,
  Text01,
  Text02,
  Wrapper,
  ButtonContainer,
  CloseButton,
} from './styles';

interface IPhoto {
  src: string;
}

interface IPMInternalProjectsGallerySmallModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  text_01: string;
  text_02?: string;
  photos: IPhoto[] | undefined;
}

const PMInternalProjectsGallerySmallModal: React.FC<IPMInternalProjectsGallerySmallModalProps> =
  ({
    isOpen,
    onClose,
    setIsOpen,
    title,
    text_01,
    text_02,
    photos,
  }: IPMInternalProjectsGallerySmallModalProps) => {
    const handleClose = (): void => {
      setIsOpen(false);
      onClose();
    };

    function handleGallery(): React.ReactNode {
      if (photos) {
        return <ResponsiveGallery images={photos} />;
      }

      return <></>;
    }

    return (
      <StyledModal
        isOpen={isOpen}
        style={{
          overlay: {
            zIndex: 999,
            overflowY: 'auto',
            position: 'fixed',
            background: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Container>
          <ButtonContainer>
            <CloseButton onClick={() => handleClose()}>
              <FaTimes size={30} />
            </CloseButton>
          </ButtonContainer>

          <Title>{title}</Title>

          <Text01>{text_01}</Text01>

          {text_02 && <Text02>{text_02}</Text02>}

          <Wrapper>{handleGallery()}</Wrapper>
        </Container>
      </StyledModal>
    );
  };

export default PMInternalProjectsGallerySmallModal;
