import React, { Dispatch, SetStateAction } from 'react';
import { FaTimes } from 'react-icons/fa';

import {
  StyledModal,
  Container,
  Title,
  Text,
  Wrapper,
  StyledYouTube,
  ButtonContainer,
  CloseButton,
} from './styles';

interface IPMInternalProjectsYouTubeModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  text: string;
}

const PMInternalProjectsYouTubeModal: React.FC<IPMInternalProjectsYouTubeModalProps> =
  ({
    isOpen,
    onClose,
    setIsOpen,
    title,
    text,
  }: IPMInternalProjectsYouTubeModalProps) => {
    const handleClose = (): void => {
      setIsOpen(false);
      onClose();
    };

    return (
      <StyledModal
        isOpen={isOpen}
        style={{
          overlay: {
            zIndex: 999,
            overflowY: 'auto',
            position: 'fixed',
            background: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Container>
          <ButtonContainer>
            <CloseButton onClick={() => handleClose()}>
              <FaTimes size={30} />
            </CloseButton>
          </ButtonContainer>

          <Title>{title}</Title>

          <Text>{text}</Text>

          <Wrapper>
            <StyledYouTube videoId="ipMvTE2Fbkk" />
          </Wrapper>
        </Container>
      </StyledModal>
    );
  };

export default PMInternalProjectsYouTubeModal;
