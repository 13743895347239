import React from 'react';

import Separator from '~/components/Separator';
import PMGalleryMultiCarousel from '~/components/PMGalleryMultiCarousel';
import PMInternalProjectsMultiCarousel from '~/components/PMInternalProjectsMultiCarousel';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import { MainContainer, Title } from './styles';

const PeopleManagement = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();

  return (
    <MainContainer id="peoplemanagement">
      <Title>
        {getLanguageInfo(currentLanguage, 'peoplemanagement_gallery_title')}
      </Title>

      <PMGalleryMultiCarousel />

      <Title>
        {getLanguageInfo(currentLanguage, 'peoplemanagement_internal_projects')}
      </Title>

      <PMInternalProjectsMultiCarousel />
      <Separator />
    </MainContainer>
  );
};

export default PeopleManagement;
